const admin = [
    {
        name: 'Dashboard',
        url: '/',
        icon: 'flaticon-381-networking',
        nested: false
    },
    {
        name: 'Admin Users',
        url: '/admin-list',
        icon: 'fa fa-user',
        nested: false
    },
    {
        name: 'User Management',
        icon: 'fa fa-users',
        nested: true,
        data: [
            {
                name: 'Users',
                url: '/',
            },
            {
                name: 'Agents',
                url: '/',
            },
        ]
    },
    {
        name: 'Recharge',
        icon: 'fa fa-battery-full',
        nested: true,
        data: [
            {
                name: 'Pending',
                url: '/',
            },
            {
                name: 'Approved',
                url: '/',
            },
            {
                name: 'Rejected',
                url: '/',
            },
        ]
    },
    {
        name: 'Withdraw',
        icon: 'fa fa-money',
        nested: true,
        data: [
            {
                name: 'Pending',
                url: '/',
            },
            {
                name: 'Approved',
                url: '/',
            },
            {
                name: 'Rejected',
                url: '/',
            },
        ]
    },
    {
        name: 'Bonus',
        icon: 'fa fa-gift',
        nested: true,
        data: [
            {
                name: 'Welcome Bonus',
                url: '/',
            },
            {
                name: 'Daily Bonus',
                url: '/',
            },
            {
                name: 'Recharge Bonus',
                url: '/',
            },
            {
                name: 'Reward Bonus',
                url: '/',
            },
            {
                name: 'Agent Bonus',
                url: '/',
            },
        ]
    },
    {
        name: 'Game Settings',
        icon: 'fa fa-cogs',
        nested: true,
        data: [
            {
                name: 'Game Name',
                url: '/',
            },
            {
                name: 'Game Permission',
                url: '/',
            },
            {
                name: 'Minimun Recharge',
                url: '/',
            },
            {
                name: 'Minimun Withdraw',
                url: '/',
            },
            {
                name: 'Upi ID',
                url: '/',
            },
            {
                name: 'Bank Account',
                url: '/',
            },
            {
                name: 'Dollar Rate',
                url: '/',
            },
            {
                name: 'Referal Link',
                url: '/',
            },
            {
                name: 'Contact Us',
                url: '/',
            },
            {
                name: 'About Us',
                url: '/',
            },
            {
                name: 'Refund Policy',
                url: '/',
            },
            {
                name: 'Terms & Conditions',
                url: '/',
            },
            {
                name: 'Referal Level',
                url: '/',
            },
            {
                name: 'Otp Api',
                url: '/',
            },
        ]
    },
    {
        name: 'Setting',
        icon: 'fa fa-cog',
        nested: true,
        data: [
            {
                name: 'Password Change',
                url: '/change-password',
            }
        ]
    },
];

export default admin;
