import React, {useState} from "react";
import {Badge, Table, Modal, Form, Button} from "react-bootstrap";
import Moment from "moment";

const columns = [
    'OrderId',
    'PlayerId',
    'Email',
    'Phone',
    'UTR',
    'UPI',
    'BANK',
    'USDT',
    'Amount',
    'Fee',
    'DollarRate',
    'Type',
    'PayBy',
    'CreateDate',
    'Status',
    'Action',
];

const TableView = ({title, data, activePage, actionShow, reloadDB, updateStatus, limit, onClick, totalPages}) => {
    const [show, setShow] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [utr, setUtr] = useState('');
    const [payBy, setPayBy] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setCurrentId(id);
        setShow(true);
    };

    const handleAccept = () => {
        if (currentId) {
            updateStatus({updateData: { id: currentId, status: 'Success', utr, payBy } });
        }
        setShow(false);
    };
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                <tr role="row">
                                    <th>SR.</th>
                                    {columns.map((d, i) => (
                                        <th key={i}>{d}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((d, i) => {
                                    if (d.orderId) {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{d.orderId}</td>
                                                <td>{d.playerId}</td>
                                                <td>{d.email}</td>
                                                <td>{d.phone}</td>
                                                <td>{d.utr}</td>
                                                <td>{d.upiId}</td>
                                                <td>
                                                    <li>{d.bank?.ifsc }</li>
                                                    <li>{d.bank?.accountNumber }</li>
                                                    <li>{d.bank?.bankName }</li>
                                                </td>
                                                <td>{d.usdt}</td>
                                                <td>{d.amount}</td>
                                                <td>{d.fee}</td>
                                                <td>{d.dollarRate}</td>
                                                <td>{d.type}</td>
                                                <td>{d.payBy}</td>
                                                <td>{Moment(d.createDate).locale('en').format('DD/MM/YYYY')}</td>
                                                <td>
                        <span
                            style={{color: d.status === 'Success' ? 'green' : d.status === 'Failed' ? 'red' : 'blue'}}>
                            {d.status === 'Success' ? 'Success' : d.status === 'Failed' ? 'Failed' : 'Pending'}
                        </span>
                                                </td>
                                                <td>
                                                    <Badge variant="success light cursor-pointer"
                                                           onClick={() => handleShow(d._id)}>
                                                        Accept
                                                    </Badge>
                                                    <Badge variant="danger light cursor-pointer"
                                                           onClick={() => updateStatus({
                                                               updateData: {
                                                                   id: d._id,
                                                                   status: 'Failed'
                                                               }
                                                           })}>
                                                        Reject
                                                    </Badge>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </table>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                <Modal.Title>Accept Withdrawal</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group controlId="formUtr">
                                            <Form.Label>UTR</Form.Label>
                                            <Form.Control type="text" placeholder="Enter UTR" value={utr} onChange={(e) => setUtr(e.target.value)} />
                                        </Form.Group>
                                        <Form.Group controlId="formPayBy">
                                            <Form.Label>PayBy</Form.Label>
                                            <Form.Control type="text" placeholder="Enter PayBy" value={payBy} onChange={(e) => setPayBy(e.target.value)} />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={handleAccept}>
                                        OK
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/*<div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to
                                    {data.length <
                                    (activePag.current + 1) * sort
                                        ? data.length
                                        : (activePag.current + 1) * sort}
                                    of {data.length} entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers">
                                    <Pagination
                                        className="pagination-primary pagination-circle"
                                        size="lg"
                                    >
                                        <a
                                            className="paginate_button previous disabled"
                                            to="Javascript:void"
                                            onClick={() =>
                                                activePag.current > 0 &&
                                                onClick(activePag.current - 1)
                                            }
                                        >
                                            Previous
                                        </a>
                                        {jobPagination.map((number, i) => (
                                            <Pagination.Item
                                                key={i}
                                                className={
                                                    activePag.current === i ? "active" : ""
                                                }
                                                onClick={() => onClick(i)}
                                            >
                                                {number}
                                            </Pagination.Item>
                                        ))}
                                        <a
                                            className="paginate_button next"
                                            to="Javascript:void"
                                            onClick={() =>
                                                activePag.current + 1 <
                                                jobPagination.length &&
                                                onClick(activePag.current + 1)
                                            }
                                        >
                                            Next
                                        </a>
                                    </Pagination>
                                </div>
                            </div>*/}
                        </div>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default TableView;
