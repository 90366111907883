const otherAdmin =  [
    {
        name: 'Dashboard',
        url: '/',
        icon: 'flaticon-381-networking',
        nested: false
    },
    {
        name: 'Admin Users',
        url: '/admin-list',
        icon: 'fa fa-user',
        nested: false
    },
    {
        name: 'Modules',
        url: '/module-list',
        icon: 'fa fa-user',
        nested: false
    },
    {
        name: 'Sub Modules',
        url: '/sub-module-list',
        icon: 'fa fa-user',
        nested: false
    },
    {
        name: 'System Settings',
        url: '/sub-module-list',
        icon: 'flaticon-381-networking',
        nested: true,
        data: [
            {
                name: 'General',
                url: '/ss-general',
            },
            {
                name: 'Communication',
                url: '/ss-communication',
            },
            {
                name: 'Data Management',
                url: '/ss-data-management',
            },
            {
                name: 'Security',
                url: '/ss-security',
            }
        ]
    },
    {
        name: 'Setting',
        url: '/change-password',
        icon: 'fa fa-cog',
        nested: false
    }
];

export default otherAdmin;