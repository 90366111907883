// reducers/authReducer.js

import { LOGIN, LOGOUT } from '../../actions/login/loginActions';

const initialState = {
    isLoggedIn: false,
    token: null,
    userType: null,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                token: action.payload.token,
                userType: action.payload.userType,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                token: null,
                userType: null,
            };
        default:
            return state;
    }
};

export default loginReducer;
