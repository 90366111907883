import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { get_player_userid_data } from "../../../../../api/secure";

const SingleUserInfo = () => {
    const location = useLocation();
    const [userDetails, setUserDetails] = useState({});
    const [loading, setLoading] = useState(true); // State to manage loading state

    const getUserDetails = async (userId) => {
        try {
            const dt = await get_player_userid_data(userId);
            console.log('dt', dt);
            setUserDetails(dt); // Store user details in state
            setLoading(false); // Set loading state to false after data fetch
        } catch (error) {
            console.error("Error fetching user details:", error);
            setLoading(false); // Ensure loading state is set to false on error
        }
    };

    useEffect(() => {
        // Extract userId from the URL
        const paths = location.pathname.split('/');
        const id = paths[paths.length - 1];

        if (id) {
            getUserDetails(id);
        }
    }, [location.pathname]);

    if (loading) {
        return <p>Loading user details...</p>; // Placeholder for loading state
    }

    return (
        <>
            <PageTitle activeMenu="User" motherMenu="User" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header text-center">
                        <h4 className="card-title">User Info</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                {/* Left column: Basic user details */}
                                <h5><strong>Name:</strong> {userDetails.user.name}</h5>
                                <h5><strong>Email:</strong> {userDetails.user.email}</h5>
                                <h5><strong>Phone:</strong> {userDetails.user.phone}</h5>
                                <h5><strong>Player ID:</strong> {userDetails.user.playerId}</h5>
                                <h5><strong>Status:</strong> {userDetails.user.status}</h5>
                                <h5><strong>Wallet:</strong> {userDetails.user.wallet}</h5>
                                <h5><strong>Wager:</strong> {userDetails.user.wager}</h5>
                                <h5><strong>Bonus:</strong> {userDetails.user.bonus}</h5>
                                <h5><strong>User Type:</strong> {userDetails.user.userType}</h5>
                                <h5><strong>Own Code:</strong> {userDetails.user.ownCode}</h5>
                            </div>
                            <div className="col-md-6">
                                {/* Right column: Additional user details */}
                                <h5><strong>Create
                                    Date:</strong> {new Date(userDetails.user.createDate).toLocaleString()}</h5>
                                <h5>
                                    <strong>Address:</strong> {userDetails.user.address?.city}, {userDetails.user.address?.state}, {userDetails.user.address?.country}, {userDetails.user.address?.pinCode}
                                </h5>
                                <h5><strong>DOB:</strong> {userDetails.user.dob}</h5>
                                <h5><strong>Gender:</strong> {userDetails.user.gender}</h5>
                                <h5><strong>Invite Code:</strong> {userDetails.user.inviteCode}</h5>
                                <h5><strong>IP Address:</strong> {userDetails.user.ipAddress}</h5>
                                <h5><strong>Is Verified:</strong> {userDetails.user.isVerified ? 'Yes' : 'No'}</h5>
                                <h5><strong>Last
                                    Activity:</strong> {new Date(userDetails.user.lastActivityTimestamp).toLocaleString()}
                                </h5>
                                <h5><strong>Last Login IP:</strong> {userDetails.user.lastLoginIpAddress}</h5>
                                <h5><strong>Last Login
                                    Date:</strong> {new Date(userDetails.user.lastLoginTimestamp).toLocaleString()}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <h5><strong>L1 Team:</strong> {userDetails.l1team}</h5>
                        <h5><strong>L2 Team:</strong> {userDetails.l2team}</h5>
                        <h5><strong>L3 Team:</strong> {userDetails.l3team}</h5>
                        <h5><strong>Total Bet Amount:</strong> {userDetails.totalBetsAmount}</h5>
                        <h5><strong>Total Bonus:</strong> {userDetails.totalBonus}</h5>
                        <h5><strong>Total Comission:</strong> {userDetails.totalCommission}</h5>
                        <h5><strong>Total Recharge:</strong> {userDetails.totalRechargeAmount}</h5>
                        <h5><strong>Total Withdraw:</strong> {userDetails.totalWithdrawAmount}</h5>
                    </div>
                </div>
                <div className='card' style={{maxHeight: "500px", overflowY: "auto"}}>
                    <div className='card-body'>
                        <h4 className="text-center mb-3">Level 1 records</h4>
                        {/* Level 1 records */}
                        {userDetails.l1records && userDetails.l1records.length > 0 && (
                            <div className="mb-4">
                                <h5><strong>Level 1 Team</strong></h5>
                                {userDetails.l1records.map((record, index) => (
                                    <div key={index} className="row border p-3 mb-2">
                                        <p><strong>Name:</strong> {record.user.name}</p>
                                        <p><strong>Total Bonus:</strong> {record.totalBonus}</p>
                                        <p><strong>Total Commission:</strong> {record.totalCommission}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='card' style={{maxHeight: "500px", overflowY: "auto"}}>
                    <div className='card-body' style={{maxHeight: "500px", overflowY: "auto"}}>
                        <h4 className="text-center mb-3">Level 2 records</h4>
                        {/* Level 2 records */}
                        {userDetails.l2records && userDetails.l2records.length > 0 && (
                            <div className="mb-4">
                                <h5><strong>Level 2 Team</strong></h5>
                                {userDetails.l2records.map((record, index) => (
                                    <div key={index} className="row border p-3 mb-2">
                                        <p><strong>Name:</strong> {record.user.name}</p>
                                        <p><strong>Total Bonus:</strong> {record.totalBonus}</p>
                                        <p><strong>Total Commission:</strong> {record.totalCommission}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='card' style={{maxHeight: "500px", overflowY: "auto"}}>
                    <div className='card-body'>
                        <h4 className="text-center mb-3">Level 3 records</h4>
                        {/* Level 3 records */}
                        {userDetails.l3records && userDetails.l3records.length > 0 && (
                            <div className="mb-4">
                                <h5><strong>Level 3 Team</strong></h5>
                                {userDetails.l3records.map((record, index) => (
                                    <div key={index} className="row border p-3 mb-2">
                                        <p><strong>Name:</strong> {record.user.name}</p>
                                        <p><strong>Total Bonus:</strong> {record.totalBonus}</p>
                                        <p><strong>Total Commission:</strong> {record.totalCommission}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='card' style={{maxHeight: "500px", overflowY: "auto"}}>
                    <div className='card-body'>
                        <h4 className="text-center mb-3">Recharge records</h4>
                        {/* Recharge records */}
                        {userDetails.rechargeRecords && userDetails.rechargeRecords.length > 0 && (
                            <div className="mb-4">
                                <h5><strong>Recharge Records</strong></h5>
                                {userDetails.rechargeRecords.map((record, index) => (
                                    <div key={index} className="row border p-3 mb-2">
                                        <p><strong>Date:</strong> {new Date(record.createDate).toLocaleString()}</p>
                                        <p><strong>Amount:</strong> {record.amount}</p>
                                        <p><strong>Status:</strong> {record.status}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='card' style={{maxHeight: "500px", overflowY: "auto"}}>
                    <div className='card-body'>
                        <h4 className="text-center mb-3">Withdraw records</h4>
                        {/* Withdraw records */}
                        {userDetails.withdrawRecords && userDetails.withdrawRecords.length > 0 && (
                            <div className="mb-4">
                                <h5><strong>Withdraw Records</strong></h5>
                                {userDetails.withdrawRecords.map((record, index) => (
                                    <div key={index} className="row border p-3 mb-2">
                                        <p><strong>Date:</strong> {new Date(record.createDate).toLocaleString()}</p>
                                        <p><strong>Amount:</strong> {record.amount}</p>
                                        <p><strong>Status:</strong> {record.status}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleUserInfo;
