import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { add_daily_bonus, get_daily_bonus } from "../../../../api/secure";
import { handleFormInput } from "../../../../common/form";

const DailyBonus = ({ history }) => {
    const [formData, setFormData] = useState({
        day1: "",
        day2: "",
        day3: "",
        day4: "",
        day5: "",
        day6: "",
        day7: "",
        allDaysAmount: "",
        type: ""
    });

    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({ e, formData: formData, payload });
        console.log("ct", ct);
        setFormData(ct);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await add_daily_bonus({ formData });
        getAllUser();
    };

    const getAllUser = async () => {
        const dt = await get_daily_bonus();
        console.log("dt", dt);

        if (dt.length > 0) {
            setFormData(dt[0]);
        }
    };

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="User" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Update Daily Bonus</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType" />
                            <div className="row">
                                {["day1", "day2", "day3", "day4", "day5", "day6", "day7"].map((day, index) => (
                                    <div className="col-lg-4 mb-2" key={index}>
                                        <div className="form-group text-left">
                                            <label className="text-label">{`Day${index + 1}*`}</label>
                                            <input
                                                type="number"
                                                name={day}
                                                value={formData[day]}
                                                className="form-control"
                                                placeholder={`Day${index + 1}`}
                                                onChange={handleBlur}
                                                required
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Random Daily Limit*</label>
                                        <input
                                            onChange={handleBlur}
                                            type="number"
                                            value={formData.allDaysAmount}
                                            name="allDaysAmount"
                                            className="form-control"
                                            placeholder="Random Daily Limit"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Type*</label>
                                        <select
                                            onChange={handleBlur}
                                            name="type"
                                            value={formData.type}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="fixed">Fixed</option>
                                            <option value="random">Random</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type="submit" className="btn btn-primary mb-2 float-right">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DailyBonus;
