import {getData, postData} from "../../import";
import swal from "sweetalert";

let url;
if (process.env.REACT_APP_ENV === "development") {
    url = process.env.REACT_APP_DEV_API;
} else {
    url = process.env.REACT_APP_PRO_API;
}


// Function to show the popup with gift code and copy button
const showGiftCodePopup = async (message, couponCode) => {
    await swal({
        title: "Gift Added Successfully",
        text: `${message}\nCoupon Code: ${couponCode}`,
        icon: "info",
        buttons: {
            confirm: {
                text: "Copy to Clipboard",
                value: "copy",
                visible: true,
                className: "swal-button--confirm",
                closeModal: false
            }
        }
    }).then((value) => {
        if (value === "copy") {
            navigator.clipboard.writeText(couponCode).then(() => {
                swal("Copied!", "Coupon code has been copied to clipboard.", "success");
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    });
};

const login_api = async ({ loginData }) => {
    const { email, password } = loginData;
    const data = { email, password }; // Simplify the data object

    console.log('Login API URL:', url + 'auth/login');
    console.log('Login API Data:', data);

    if (email && password) {
        try {
            return await postData(url + 'auth/login', data); // Return the response data
        } catch (error) {
            console.error('Error in login_api:', error);
            throw error; // Rethrow the error for handling in the action
        }
    } else {
        throw new Error('Email and password are mandatory'); // Rethrow the error for handling in the action
    }
};


const change_password_api = async ({loginData}) => {
    const {currentPassword, newPassword, confirmPassword} = {...loginData};
    //let data : {email: any, password: any} = {"email": loginData.email, "password": loginData.password};
    const data = {currentPassword, newPassword};
    if (currentPassword && newPassword && confirmPassword) {
        if (newPassword !== confirmPassword) {
            await swal(
                "Warning!",
                "New password not match with confirm password",
                "info"
            );
            return true;
        }
        const loginRes = await postData(url + 'auth/change-password', data);
        if (loginRes.message === 'Password changed successfully') {
            localStorage.clear();
            window.location.href = '/';
            return true;

            //this.props.history.push('/');
            //history.push("/");
        } else {
            //alert(loginRes.message);
            const newLoginData = {...loginData};
            newLoginData["login_fail"] = true;
            newLoginData["msg"] = loginRes.message;
            await swal(
                "Warning!",
                loginRes.message,
                "info"
            )
            return newLoginData
        }
    } else {
        await swal(
            "Warning!",
            "Email and password is mandatory",
            "info"
        )
    }
}

const add_user_api = async ({formData}) => {
    console.log("registerData", formData);
    const {email, phone, password, userType} = {...formData};
    const data = {
        email: email,
        phone: phone,
        password: password,
        userType: userType,
    };

    if (email && password && userType) {
        //const registerRes = await postData(url + 'signup', data);
        const registerRes = await postData(url + 'auth/add-user', data);

        if (registerRes.message === "User created successfully") {
            await swal(
                "Warning!",
                "User Add Successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                registerRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = registerRes.message;
            return newRegistrationData
        }
    } else {
        await swal(
            "Warning!",
            "All fields are required",
            "info"
        )
    }
}

const admin_update_status = async ({updateData}) => {
    try {
        const {userId, status} = {...updateData};
        // Check if email and status are provided
        if (!userId || !status) {
            await swal(
                "Warning!",
                "UserId and status are required",
                "info"
            );
            return;
        }

        const data = { userId, status };
        console.log('Data:', data);
        const adminUpdateResponse = await postData(url + 'auth/admin-update-status', data);
        console.log(adminUpdateResponse);

        // Check the message in the response
        if (adminUpdateResponse.message === 'Status updated successfully') {
            await swal(
                "Success",
                "User status updated successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                adminUpdateResponse.message,
                "info"
            );
            const newUpdateData = { ...status };
            newUpdateData["update_fail"] = true;
            newUpdateData["msg"] = adminUpdateResponse.message;
            return newUpdateData;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};
const player_update_status = async ({updateData}) => {
    try {
        const {userId, status} = {...updateData};
        // Check if email and status are provided
        if (!userId || !status) {
            await swal(
                "Warning!",
                "UserId and status are required",
                "info"
            );
            return;
        }

        const data = { userId, status };
        console.log('Data:', data);
        const playerUpdateResponse = await postData(url + 'auth/player-update-status', data);


        // Check the message in the response
        if (playerUpdateResponse.message === "Status updated successfully") {
            await swal(
                "Success",
                "User status updated successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                playerUpdateResponse.message,
                "info"
            );
            const newUpdateData = { ...status };
            newUpdateData["update_fail"] = true;
            newUpdateData["msg"] = playerUpdateResponse.message;
            return newUpdateData;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};

const player_update_details = async ({updateData}) => {
    try {
        // const {userId} = {...updateData};
        // // Check if email and status are provided
        // if (!userId ) {
        //     await swal(
        //         "Warning!",
        //         "UserId are required",
        //         "info"
        //     );
        //     return;
        // }

        // const data = { userId };
        console.log('Data:', updateData);
        const playerUpdateResponse = await postData(url + 'auth/player-update-details', updateData);


        // Check the message in the response
        if (playerUpdateResponse.message === "Detail updated successfully") {
            await swal(
                "Success",
                "User Detail updated successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                playerUpdateResponse.message,
                "info"
            );
            const newUpdateData = {  };
            newUpdateData["update_fail"] = true;
            newUpdateData["msg"] = playerUpdateResponse.message;
            return newUpdateData;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};

const agents_update_status = async ({updateData}) => {
    try {
        const {userId, status} = {...updateData};
        // Check if email and status are provided
        if (!userId || !status) {
            await swal(
                "Warning!",
                "UserId and status are required",
                "info"
            );
            return;
        }

        const data = { userId, status };
        console.log('Data:', data);
        const agentsUpdateResponse = await postData(url + 'auth/agents-update-status', data);

        // Check the message in the response
        if (agentsUpdateResponse.message === "Status updated successfully") {
            await swal(
                "Success",
                "User status updated successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                agentsUpdateResponse.message,
                "info"
            );
            const newUpdateData = { ...status };
            newUpdateData["update_fail"] = true;
            newUpdateData["msg"] = agentsUpdateResponse.message;
            return newUpdateData;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};



const recharge_update_status = async ({updateData}) => {
    try {
        const {id, status} = {...updateData};
        // Check if email and status are provided
        if (!id || !status) {
            await swal(
                "Warning!",
                "id and status are required",
                "info"
            );
            return;
        }

        const data = { id, status };
        console.log('Data:', data);
        const rechargeUpdateResponse = await postData(url + 'auth/update-recharge-status', updateData);

        // Check the message in the response
        if (rechargeUpdateResponse.message === "Status updated successfully") {
            await swal(
                "Success",
                "User status updated successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                rechargeUpdateResponse.message,
                "info"
            );
            const newUpdateData = { ...status };
            newUpdateData["update_fail"] = true;
            newUpdateData["msg"] = rechargeUpdateResponse.message;
            return newUpdateData;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};



const withdraw_update_status = async ({updateData}) => {
    try {
        const {id, status} = {...updateData};
        // Check if email and status are provided
        if (!id || !status) {
            await swal(
                "Warning!",
                "id and status are required",
                "info"
            );
            return;
        }

        const data = { id, status };
        console.log('Data:', data);
        const withdrawUpdateResponse = await postData(url + 'auth/update-withdraw-status', updateData);

        // Check the message in the response
        if (withdrawUpdateResponse.message === "Status updated successfully") {
            await swal(
                "Success",
                "User status updated successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                withdrawUpdateResponse.message,
                "info"
            );
            const newUpdateData = { ...status };
            newUpdateData["update_fail"] = true;
            newUpdateData["msg"] = withdrawUpdateResponse.message;
            return newUpdateData;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};

const add_daily_bonus = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const dailyRes = await postData(url + 'setting/update-daily-rewards', formData);

        if (dailyRes.message === "User created successfully") {
            await swal(
                "Warning!",
                "Daily Bonus Updated Successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                dailyRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = dailyRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}



const add_all_setting = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const dailyRes = await postData(url + 'setting/update-all-setting', formData);

        if (dailyRes.message === "User created successfully") {
            await swal(
                "Warning!",
                "Daily Bonus Updated Successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                dailyRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = dailyRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}


const add_all_bet_setting = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const dailyRes = await postData(url + 'setting/update-all-bet-setting', formData);

        if (dailyRes.message === "User created successfully") {
            await swal(
                "Warning!",
                "Daily Bonus Updated Successfully",
                "success"
            );
            return true;
        } else {
            await swal(
                "Warning!",
                dailyRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = dailyRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}



const update_task_status = async ({updateData}) => {
    try {

        const taskUpdateResponse = await postData(url + 'setting/update-task', updateData);

        // Check the message in the response
        if (taskUpdateResponse.message) {
            await swal(
                "Warning!",
                taskUpdateResponse.message,
                "info"
            );
            return taskUpdateResponse;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};

const add_task_api = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const taskRes = await postData(url + 'setting/create-task', formData);

        if (taskRes.message) {
            await swal(
                "Warning!",
                taskRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = taskRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}



const update_gift_status = async ({updateData}) => {
    try {

        const giftUpdateResponse = await postData(url + 'setting/update-gift-code', updateData);

        // Check the message in the response
        if (giftUpdateResponse.message) {
            await swal(
                "Warning!",
                giftUpdateResponse.message,
                "info"
            );
            return giftUpdateResponse;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};

const add_new_giftCode = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const giftRes = await postData(url + 'setting/create-gift-code', formData);

        if (giftRes.message === 'Gift added successfully') {
            await showGiftCodePopup(giftRes.message, giftRes.data.couponCode);
        } else if (giftRes.message) {
            await swal(
                "Warning!",
                giftRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = giftRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}



const add_new_next_result = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const newResultRes = await postData(url + 'setting/next-result', formData);

        if (newResultRes.message === 'Next Result updated successfully' || newResultRes.message === 'Next Result added successfully' ) {
            await swal(
                "Success!",
                newResultRes.message,
                "success"
            );
        } else if (newResultRes.message) {
            await swal(
                "Warning!",
                newResultRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = newResultRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}





const update_recharge_bonus_status = async ({updateData}) => {
    try {

        const rechargeUpdateResponse = await postData(url + 'setting/update-recharge-bonus', updateData);

        // Check the message in the response
        if (rechargeUpdateResponse.message) {
            await swal(
                "Warning!",
                rechargeUpdateResponse.message,
                "info"
            );
            return rechargeUpdateResponse;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};


const update_recharge_bonus_detail = async ({updateData}) => {
    try {

        const rechargeUpdateResponse = await postData(url + 'setting/update-recharge-bonus-detail', updateData);

        // Check the message in the response
        if (rechargeUpdateResponse.message) {
            await swal(
                "Warning!",
                rechargeUpdateResponse.message,
                "info"
            );
            return rechargeUpdateResponse;
        }
    } catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
};

const add_recharge_bonus_api = async ({formData}) => {
    try {
        console.log("dailyData", formData);
        const rechargeRes = await postData(url + 'setting/create-recharge-bonus', formData);

        if (rechargeRes.message) {
            await swal(
                "Warning!",
                rechargeRes.message,
                "info"
            )
            const newRegistrationData = {...formData};
            newRegistrationData["registration_fail"] = true;
            newRegistrationData["msg"] = rechargeRes.message;
            return newRegistrationData
        }
    }catch (error) {
        console.error('Error updating status:', error.message);
        await swal(
            "Error",
            "Failed to update user status",
            "error"
        );
    }
}





const get_admin_user = async () => {
     return await getData(url + 'auth/get-users');
}

const get_player_users = async () => {
    return await getData(url + 'auth/get-player-users');
}

const get_player_userid_data = async (userId) => {
    return await getData(url + `auth/get-player-user-data?id=${userId}`);
}

const get_agents_user = async () => {
    return await getData(url + 'auth/get-agents-user');
}


const get_pending_recharge = async () => {
     return await getData(url + 'auth/get-pending-recharge');
}

const get_success_recharge = async () => {
    return await getData(url + 'auth/get-success-recharge');
}

const get_failed_recharge = async () => {
    return await getData(url + 'auth/get-failed-recharge');
}


const get_pending_withdraw = async () => {
     return await getData(url + 'auth/get-pending-withdraw');
}

const get_success_withdraw = async () => {
    return await getData(url + 'auth/get-success-withdraw');
}

const get_failed_withdraw = async () => {
    return await getData(url + 'auth/get-failed-withdraw');
}

const get_daily_bonus = async () => {
    return await getData(url + 'setting/get-daily-bonus');
}

const get_all_setting = async () => {
    return await getData(url + 'setting/get-all-setting');
}

const get_all_bet_setting = async () => {
    return await getData(url + 'setting/get-all-bet-setting');
}

const get_tasks = async () => {
    return await getData(url + 'setting/get-task');
}

const get_recharge_bonus_list = async () => {
    return await getData(url + 'setting/get-recharge-bonus');
}
const get_all_giftCode = async () => {
    return await getData(url + 'setting/get-all-gift-code');
}

const get_all_next_result = async () => {
    return await getData(url + 'setting/get-all-next-result');
}

const dashboard_data = async () => {
    return await getData(url + 'auth/dashboard-data');
}

export {login_api, change_password_api, add_user_api, admin_update_status, get_admin_user, get_player_users,
    get_agents_user, agents_update_status, player_update_status, get_failed_withdraw, get_failed_recharge,
    get_pending_recharge, get_success_recharge, get_success_withdraw, get_pending_withdraw,
    withdraw_update_status, recharge_update_status, get_daily_bonus, add_daily_bonus, get_tasks,
    add_task_api, update_task_status, get_recharge_bonus_list, add_recharge_bonus_api, update_recharge_bonus_status,
    update_recharge_bonus_detail, get_all_setting, add_all_setting, add_all_bet_setting, get_all_bet_setting,
    get_all_giftCode, add_new_giftCode, update_gift_status, add_new_next_result, get_all_next_result, player_update_details,
    get_player_userid_data, dashboard_data,
    url};
