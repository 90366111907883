import React, { Component } from "react";
import { connect } from 'react-redux';

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

import { superAdmin, otherAdmin, handler, support, admin } from '../../../common/sideBar';
import {logout} from "../../../redux/actions/login/loginActions";

///
// import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      //  this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
    componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
	  
	  
	  //sidebar icon Heart blast
	  let handleheartBlast = document.querySelector('.heart');

        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
   };

    handleLogout = () => {
       // Dispatch the logout action
       this.props.logout();
       // Clear local storage and redirect to the login page
       localStorage.clear();
       window.location.href = '/';
    }

   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      let sideBarData = [];
       const userType = localStorage.getItem('userType');
       if (userType === "sa"){
           sideBarData = superAdmin;
       }else if (userType === "ad"){
           sideBarData = admin;
       }else if (userType === "su"){
           sideBarData = support;
       }else if (userType === "ha"){
           sideBarData = handler;
       }else {
           sideBarData = otherAdmin;
       }

      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                  {
                     sideBarData.map((item, i) => {
                        if (item.nested) {
                           return (
                               <li className={`${path === item.url ? "mm-active" : ""}`}>
                                  <Link
                                      className="has-arrow ai-icon"
                                      to={item.url}
                                      aria-expanded="false"
                                  >
                                     <i className={item.icon}></i>
                                     <span className="nav-text">{item.name}</span>
                                  </Link>
                                  {
                                      item.data.length > 0 &&
                                      <ul aria-expanded="false">
                                         {
                                            item.data.map((d, i) => (
                                                <li>
                                                   <Link
                                                       className={`${path === item.url ? "mm-active" : ""}`}
                                                       onClick={() => this.props.onClick()}
                                                       to={d.url}>
                                                      {d.name}
                                                   </Link>
                                                </li>
                                            ))
                                         }
                                      </ul>
                                  }
                               </li>
                           )
                        } else {
                           return (
                               <li className={`${path === item.url ? "mm-active" : ""}`}>
                                  <Link
                                      className="ai-icon"
                                      to={item.url}
                                      aria-expanded="false"
                                  >
                                     <i className={item.icon}></i>
                                     <span className="nav-text">{item.name}</span>
                                  </Link>
                               </li>
                           )
                        }
                     })
                  }

                  <li className={""}>
                     <a
                         onClick={this.handleLogout}
                         href="JavaScript:void"
                         className="ai-icon"
                         aria-expanded="false"
                     >
                        <i className="fa fa-power-off"></i>
                        <span className="nav-text">Logout</span>
                     </a>
                  </li>
               </MM>

               <div className="copyright">
                  <p className="fs-14 font-w200">
                     <strong className="font-w400">
                        APJ Development Ticketing Admin
                     </strong>{" "}
                     Dashboard © 2023 All Rights Reserved
                  </p>
                  <p>
                     Made with <span class="heart"></span> by APJ Development
                  </p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default connect(null, { logout })(SideBar);
