import React, {useState, useEffect} from "react";

import PageTitle from "../../../layouts/PageTitle";
import {handleFormInput} from "../../../../common/form"
import {
    add_recharge_bonus_api,
    get_recharge_bonus_list, update_recharge_bonus_detail,
    update_recharge_bonus_status,
} from "../../../../api/secure";
import TableView from "./recharge/TableView";

const RechargeBonus = ({history}) => {
    const [formData, setFormData] = useState({});
    const [users, setUsers] = useState([]);
    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({e, formData: formData, payload});
        //console.log("ct", ct);
        setFormData(ct);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        await add_recharge_bonus_api({formData});
        getAllUser();
    };

    const updateStatus = async (updateData) => {
        await update_recharge_bonus_status(updateData);
        getAllUser();
    }
    const updateDetail = async (updateData) => {
        await update_recharge_bonus_detail(updateData);
        getAllUser();
    }

    const getAllUser = async () => {
        const dt = await get_recharge_bonus_list();
        console.log("dt", dt);
        setUsers(dt);
    };

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="User"/>
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add Task</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType"/>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Min Amount*</label>
                                        <input
                                            type="number"
                                            name="min"
                                            className="form-control"
                                            placeholder="Min Amount"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Max Amount*</label>
                                        <input
                                            type="number"
                                            name="max"
                                            className="form-control"
                                            placeholder="Max Amount"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bonus Amount*</label>
                                        <input
                                            type="number"
                                            name="bonus"
                                            className="form-control"
                                            placeholder="Bonus Amount"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label"> Type*</label>
                                        <select
                                            onChange={handleBlur}
                                            name="type"
                                            defaultValue={''}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="Player">Player</option>
                                            <option value="Agent">Agent</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type='submit' className='btn btn-primary mb-2 float-right'>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row">
                {users.length > 0 &&
                    <TableView
                        title={"Recharge Bonus List"}
                        reloadDB={() => getAllUser()}
                        data={users}
                        updateStatus={(updateData) => updateStatus(updateData)}
                        updateDetails={(updateData) => updateDetail(updateData)}
                        actionShow={false}
                    />
                }
            </div>
        </>
    );
};

export default RechargeBonus;
