import React, {useState, useEffect} from "react";
import PageTitle from "../../../layouts/PageTitle";
import {handleFormInput} from "../../../../common/form"
import {get_pending_recharge, recharge_update_status} from "../../../../api/secure";
import TableView from "./pending/TableView";

const PendingRechargeList = ({history}) => {
    const [formData, setFormData] = useState({});
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const limit = 10; // Number of items per page

    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({ e, formData: formData, payload });
        setFormData(ct);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const searchTerm = formData.search.toLowerCase();
        let filteredData = users;
        if (searchTerm.trim() !== "") {
            filteredData = users.filter((user) =>
                (((typeof user.orderId === 'number' || typeof user.orderId === 'string') && user.orderId.toString().includes(searchTerm)) ||
                    (typeof user.email === 'string' && user.email.toLowerCase().includes(searchTerm)) ||
                    ((typeof user.phone === 'number' || typeof user.phone === 'string') && user.phone.toString().includes(searchTerm)) ||
                    ((typeof user.playerId === 'number' || typeof user.playerId === 'string') && user.playerId.toString().includes(searchTerm)) ||
                    ((typeof user.utr === 'number' || typeof user.utr === 'string') && user.utr.toString().includes(searchTerm)) ||
                    ( typeof user.payTo === 'string' && user.payTo.toString().includes(searchTerm))
                )
            );
        }
        setFilteredUsers(filteredData);
        setActivePage(0); // Reset active page to first page when filtering
    };

    const updateStatus = async (updateData) => {
        await recharge_update_status(updateData);
        getAllUser();
    }

    const getAllUser = async () => {
        const dt = await get_pending_recharge();
        setUsers(dt);
        setFilteredUsers(dt); // Initially, set filtered users to all users
    };

    useEffect(() => {
        getAllUser();
    }, []);

    const onClick = async (page) => {
        setActivePage(page); // Update active page
    };

    return (
        <>
            <PageTitle activeMenu="User" motherMenu="User" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Search Pending Recharge</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType" />
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Search*</label>
                                        <input
                                            type="text"
                                            name="search"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type='submit' className='btn btn-primary mb-2 float-right'>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row">
                <TableView
                    title={"Users"}
                    reloadDB={() => getAllUser()}
                    data={filteredUsers}
                    updateStatus={(updateData) => updateStatus(updateData)}
                    actionShow={false}
                    onClick={onClick}
                    limit={limit}
                    activePage={activePage}
                    totalPages={Math.ceil(filteredUsers.length / limit)}
                />
            </div>
        </>
    );
};


export default PendingRechargeList;
