import React, {useRef, useState} from "react";
import {Badge, Table} from "react-bootstrap";
import Moment from "moment";
import swal from "sweetalert";


const columns = ['Title', 'GiftCode', 'Type', 'Fixed Amount', 'Random Amount', 'Expire At', 'Used', 'Max Use', 'Used Amt', 'Create At',  'Status',  'Action',];


const TableView = ({title, data, actionShow, reloadDB, updateStatus}) => {
    const [copiedCoupon, setCopiedCoupon] = useState(null);
    const couponRef = useRef(null);

    const copyCouponCode = (couponCode) => {
        navigator.clipboard.writeText(couponCode);
        setCopiedCoupon(couponCode);

        // Show SweetAlert notification
        swal(
            "Coupon Code Copied!",
            `Coupon Code ${couponCode} has been copied.`,
            "success"
        )
    };

    return (<div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                <tr role="row">
                                    <th>SR.</th>
                                    {columns.map((d, i) => (<th key={i}>{d}</th>))}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((d, i) => {
                                    if (d.type) {
                                        return (<tr key={i}>
                                            {/*<td key={i}>
                                                        {
                                                            d.profile_image &&
                                                            <img
                                                                src={Constants.image_url + '' + d.profile_image}
                                                                className="rounded-circle"
                                                                alt="profile"
                                                                width={50}
                                                                height={50}
                                                            />
                                                        }
                                                    </td>*/}
                                            <td>
                                                {i + 1}
                                            </td>
                                            <td>
                                                {d.title}
                                            </td>
                                            <td>
                                                    <span ref={couponRef}
                                                          className="cursor-pointer"
                                                          onClick={() => copyCouponCode(d.couponCode)}
                                                          style={{textDecoration: "underline", color: "blue"}}
                                                    >
                                                        {d.couponCode}
                                                    </span>
                                            </td>
                                            <td>
                                                {d.type}
                                            </td>
                                            <td>
                                                {d.fixedAmount}
                                            </td>
                                            <td>
                                                {d.randomAmount}
                                            </td>
                                            <td>
                                                {Moment(d.expireDateTime).locale("en").format("DD/MM/YYYY hh:mm A")}
                                            </td>
                                            <td>
                                                {d.used}
                                            </td>
                                            <td>
                                                {d.maxUse}
                                            </td>
                                            <td>
                                                {d.usedAmount}
                                            </td>

                                            <td>
                                                {Moment(d.createdAt).locale('en').format('DD/MM/YYYY')}
                                            </td>
                                            <td>
                                                        <span style={{color: d.status === true ? 'green' : 'red'}}>
                                                            {d.status === true ? 'Active' : 'UnActive'}
                                                        </span>
                                            </td>
                                            <td>
                                                {d.status === true ? (<Badge variant="danger light cursor-pointer"
                                                                             onClick={() => updateStatus({
                                                                                 updateData: {
                                                                                     id: d._id, status: false
                                                                                 }
                                                                             })}>
                                                    Deactivate
                                                </Badge>) : (<Badge variant="success light cursor-pointer"
                                                                    onClick={() => updateStatus({
                                                                        updateData: {
                                                                            id: d._id, status: true
                                                                        }
                                                                    })}>
                                                    Activate
                                                </Badge>)}
                                            </td>

                                        </tr>)
                                    }
                                })}
                                </tbody>
                            </table>

                            {/*    <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to
                                    {data.length <
                                    (activePag.current + 1) * sort
                                        ? data.length
                                        : (activePag.current + 1) * sort}
                                    of {data.length} entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers">
                                    <Pagination
                                        className="pagination-primary pagination-circle"
                                        size="lg"
                                    >
                                        <a
                                            className="paginate_button previous disabled"
                                            to="Javascript:void"
                                            onClick={() =>
                                                activePag.current > 0 &&
                                                onClick(activePag.current - 1)
                                            }
                                        >
                                            Previous
                                        </a>
                                        {jobPagination.map((number, i) => (
                                            <Pagination.Item
                                                key={i}
                                                className={
                                                    activePag.current === i ? "active" : ""
                                                }
                                                onClick={() => onClick(i)}
                                            >
                                                {number}
                                            </Pagination.Item>
                                        ))}
                                        <a
                                            className="paginate_button next"
                                            to="Javascript:void"
                                            onClick={() =>
                                                activePag.current + 1 <
                                                jobPagination.length &&
                                                onClick(activePag.current + 1)
                                            }
                                        >
                                            Next
                                        </a>
                                    </Pagination>
                                </div>
                            </div>*/}
                        </div>
                    </Table>
                </div>
            </div>
        </div>);
};

export default TableView;
