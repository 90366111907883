import React, { useState } from "react";
import { Badge, Button, Form, Modal, Pagination, Table } from "react-bootstrap";
import Moment from "moment";
import {Link} from "react-router-dom";

const columns = [
    'PlayerId',
    'Name',
    'Email',
    'Phone',
    'Password',
    'Money',
    'Wager',
    'Bonus',
    'Invite',
    'OwnCode',
    'User Type',
    'Create Date',
    'Status',
    'Action',
    'Update',
    'Info',
];

const userTypeNames = {
    "p": "Player",
    "ag": "Agent"
};

const TableView = ({ title, data, activePage, actionShow, reloadDB, updateStatus, limit, onClick, totalPages, updateDetails }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [wallet, setWallet] = useState('');
    const [wager, setWager] = useState('');
    const [bonus, setBonus] = useState('');
    const [userType, setUserType] = useState('p');

    const handleUpdateClick = (userId) => {
        const user = data.find(u => u._id === userId);
        setSelectedUser(user);
        setWallet(user.wallet);
        setWager(user.wager);
        setBonus(user.bonus);
        setUserType(user.userType);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleSubmit = () => {
        const updatedUser = {
            ...selectedUser,
            wallet,
            wager,
            bonus,
            userType
        };
        // Handle user update logic here (e.g., make API call to update user)
        console.log('Updated User:', updatedUser);
        updateDetails(updatedUser);
        // Call reloadDB or another function to refresh the data
        reloadDB();
        handleClose();
    };

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                <tr role="row">
                                    <th>SR.</th>
                                    {columns.map((d, i) => (
                                        <th key={i}>{d}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((d, i) => {
                                    if (d.email) {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{d.playerId}</td>
                                                <td>{d.name}</td>
                                                <td>{d.email}</td>
                                                <td>{d.phone}</td>
                                                <td>{d.confirmPassword}</td>
                                                <td>{d.wallet}</td>
                                                <td>{d.wager}</td>
                                                <td>{d.bonus}</td>
                                                <td>{d.inviteCode}</td>
                                                <td>{d.ownCode}</td>
                                                <td>
                                                        <span style={{color: 'green'}}>
                                                            {d.userType === "p" ? userTypeNames["p"] : userTypeNames["ag"]}
                                                        </span>
                                                </td>
                                                <td>{Moment(d.created_date).locale('en').format('DD/MM/YYYY')}</td>
                                                <td>
                                                        <span
                                                            style={{color: d.status === 'active' ? 'green' : d.status === 'unVerified' ? 'blue' : 'red'}}>
                                                            {d.status === 'active' ? 'Active' : d.status === 'unVerified' ? 'Unverified' : 'Blocked'}
                                                        </span>
                                                </td>
                                                <td>
                                                    {d.status === "active" ? (
                                                        <Badge variant="danger light cursor-pointer"
                                                               onClick={() => updateStatus(d._id, 'block')}>
                                                            Deactivate
                                                        </Badge>
                                                    ) : (
                                                        <Badge variant="success light cursor-pointer"
                                                               onClick={() => updateStatus(d._id, 'active')}>
                                                            Activate
                                                        </Badge>
                                                    )}
                                                </td>
                                                <td>
                                                    <Badge variant="warning light cursor-pointer"
                                                           onClick={() => handleUpdateClick(d._id)}>
                                                        Update
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <Link to={`/userInfo/${d._id}`} className="primary light cursor-pointer">
                                                        Info
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </table>
                            <div className="d-flex row align-items-center">
                                <div className="ml-4 dataTables_info">
                                    Showing {activePage * limit + 1} to&nbsp;
                                    {data.length < (activePage + 1) * limit ? data.length : (activePage + 1) * limit} &nbsp;
                                    of {data.length} entries
                                </div>
                                <div className="ml-auto dataTables_paginate paging_simple_numbers">
                                    <Pagination className="pagination-primary pagination-circle" size="lg">
                                        <a
                                            className="paginate_button previous disabled"
                                            href="#"
                                            onClick={() => activePage > 0 && onClick(activePage - 1)}
                                        >
                                            Previous
                                        </a>
                                        {pageNumbers.map((number, i) => (
                                            <Pagination.Item
                                                key={i}
                                                className={activePage === i ? "active" : ""}
                                                onClick={() => onClick(i)}
                                            >
                                                {number}
                                            </Pagination.Item>
                                        ))}
                                        <a
                                            className="paginate_button next"
                                            href="#"
                                            onClick={() => activePage + 1 < totalPages && onClick(activePage + 1)}
                                        >
                                            Next
                                        </a>
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </Table>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formWallet">
                            <Form.Label>Wallet</Form.Label>
                            <Form.Control
                                type="number"
                                value={wallet}
                                onChange={(e) => setWallet(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formWager">
                            <Form.Label>Wager</Form.Label>
                            <Form.Control
                                type="number"
                                value={wager}
                                onChange={(e) => setWager(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBonus">
                            <Form.Label>Bonus</Form.Label>
                            <Form.Control
                                type="number"
                                value={bonus}
                                onChange={(e) => setBonus(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formUserType">
                            <Form.Label>User Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={userType}
                                onChange={(e) => setUserType(e.target.value)}
                            >
                                <option value="p">Player</option>
                                <option value="ag">Agent</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TableView;
