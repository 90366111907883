import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import SimpleDataTable from "./SimpleDataTable";
const DataTable = () => {
   return (
      <Fragment>
         <PageTitle activeMenu="Datatable" motherMenu="Table" />
         <div className="row">
            <SimpleDataTable></SimpleDataTable>s
         </div>
      </Fragment>
   );
};

export default DataTable;
