import React, { useState } from "react";

import PageTitle from "../../../layouts/PageTitle";
import { change_password_api } from "../../../../api/secure"
import { handleFormInput } from "../../../../common/form"


const Login = ({ history }) => {
    const [loginData, setLoginData] = useState({});
    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({e, formData: loginData, payload});
        setLoginData(ct);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        //history.push("/");
        await change_password_api({loginData});
    };

    return (
        <>
            <PageTitle activeMenu="Setting" motherMenu="User"/>
            <div className="col-12">
               {/* <div className="card overflow-hidden"
                     style={{height: 'auto'}}>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between">
                            <span className="mb-0">Name</span>{" "}
                            <strong className="text-muted">{this.state.name}</strong>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <span className="mb-0">Email</span>{" "}
                            <strong className="text-muted">{this.state.email}</strong>
                        </li>
                    </ul>
                </div>*/}

                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Change Password</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType"/>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Current Password</label>
                                        <input
                                            type="password"
                                            name="currentPassword"
                                            className="form-control"
                                            placeholder="Current Password"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">New Password*</label>
                                        <input
                                            onChange={handleBlur}
                                            type="password"
                                            name="newPassword"
                                            className="form-control"
                                            placeholder="New Password"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Confirm Password</label>
                                        <input
                                            onChange={handleBlur}
                                            type="password"
                                            name="confirmPassword"
                                            className="form-control"
                                            placeholder="New Password"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type='submit' className='btn btn-primary mb-2 float-right'>
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
