import React, { useState } from "react";
import { Link } from "react-router-dom";
import { handleFormInput } from "../../common/form"
import {login, logout} from "../../redux/actions/login/loginActions";
import {connect} from "react-redux";

const Login = ({ isLoggedIn, login, logout }) => {
   const [loginData, setLoginData] = useState({});
   const handleBlur = (e, payload = {}) => {
      let ct = handleFormInput({e, formData: loginData, payload});
      setLoginData(ct);
   };
   const submitHandler = (e) => {
      e.preventDefault();
      login(loginData);
   };

   return (
      <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                              <h4 className="text-center mb-4">
                                 Sign in your account
                              </h4>
                              <form
                                 action=""
                                 onSubmit={(e) => submitHandler(e)}
                              >
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Email</strong>
                                    </label>
                                    <input
                                       type="email"
                                       className="form-control"
                                       placeholder={"hello@example.com"}
                                       defaultValue=""
                                       name="email"
                                       onChange={(e) => handleBlur(e, {valType: "lowerCase"})}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Password</strong>
                                    </label>
                                    <input
                                       type="password"
                                       placeholder={"password"}
                                       className="form-control"
                                       defaultValue=""
                                       name="password"
                                       onChange={handleBlur}
                                    />
                                 </div>
                                 <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                       <div className="custom-control custom-checkbox ml-1">
                                          <input
                                             type="checkbox"
                                             className="custom-control-input"
                                             id="basic_checkbox_1"
                                          />
                                          <label
                                             className="custom-control-label"
                                             htmlFor="basic_checkbox_1"
                                          >
                                             Remember my preference
                                          </label>
                                       </div>
                                    </div>
                                    <div className="form-group">
                                       <Link to="/forgot-password">
                                          Forgot Password?
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="text-center">
                                    <input
                                       type="submit"
                                       value="Sign Me In"
                                       className="btn btn-primary btn-block"
                                    />
                                 </div>
                              </form>
                              {/*<div className="new-account mt-3">
                                 <p>
                                    Don't have an account?{" "}
                                    <Link
                                       className="text-primary"
                                       to="/signup"
                                    >
                                       Sign up
                                    </Link>
                                 </p>
                              </div>*/}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({
   isLoggedIn: state.isLoggedIn,
});

const mapDispatchToProps = {
   login,
   logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
