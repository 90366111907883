import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { handleFormInput } from "../../../../common/form";
import {add_all_bet_setting, get_all_bet_setting} from "../../../../api/secure";

const AllBetSetting = ({ history }) => {
    const [formData, setFormData] = useState({
        ace: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
        seven: "",
        eight: "",
        nine: "",
        ten: "",
        jack: "",
        queen: "",
        king: "",
        small: "",
        tie: "",
        big: ""
    });

    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({ e, formData: formData, payload });
        console.log("ct", ct);
        setFormData(ct);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await add_all_bet_setting({ formData });
        getAllUser();
    };

    const getAllUser = async () => {
        const dt = await get_all_bet_setting();
        console.log("dt", dt);

        if (dt.length > 0) {
            setFormData(dt[0]);
        }
    };

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="User" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Update Settings</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType" />
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For Ace(A)</label>
                                        <input
                                            type="number"
                                            name='ace'
                                            value={formData.ace}
                                            className="form-control"
                                            placeholder='Bet Amount For Ace(A)'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 2</label>
                                        <input
                                            type="number"
                                            name='two'
                                            value={formData.two}
                                            className="form-control"
                                            placeholder='Bet Amount For 2'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 3</label>
                                        <input
                                            type="number"
                                            name='three'
                                            value={formData.three}
                                            className="form-control"
                                            placeholder='Bet Amount For 3'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 4</label>
                                        <input
                                            type="number"
                                            name='four'
                                            value={formData.four}
                                            className="form-control"
                                            placeholder='Bet Amount For 4'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 5</label>
                                        <input
                                            type="number"
                                            name='five'
                                            value={formData.five}
                                            className="form-control"
                                            placeholder='Bet Amount For 5'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 6</label>
                                        <input
                                            type="number"
                                            name='six'
                                            value={formData.six}
                                            className="form-control"
                                            placeholder='Bet Amount For 6'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 7</label>
                                        <input
                                            type="number"
                                            name='seven'
                                            value={formData.seven}
                                            className="form-control"
                                            placeholder='Bet Amount For 7'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 8</label>
                                        <input
                                            type="number"
                                            name='eight'
                                            value={formData.eight}
                                            className="form-control"
                                            placeholder='Bet Amount For 8'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 9</label>
                                        <input
                                            type="number"
                                            name='nine'
                                            value={formData.nine}
                                            className="form-control"
                                            placeholder='Bet Amount For 9'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For 10</label>
                                        <input
                                            type="number"
                                            name='ten'
                                            value={formData.ten}
                                            className="form-control"
                                            placeholder='Bet Amount For 10'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For J</label>
                                        <input
                                            type="number"
                                            name='jack'
                                            value={formData.jack}
                                            className="form-control"
                                            placeholder='Bet Amount For J'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For Q</label>
                                        <input
                                            type="number"
                                            name='queen'
                                            value={formData.queen}
                                            className="form-control"
                                            placeholder='Bet Amount For Q'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For K</label>
                                        <input
                                            type="number"
                                            name='king'
                                            value={formData.king}
                                            className="form-control"
                                            placeholder='Bet Amount For K'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For Small</label>
                                        <input
                                            type="number"
                                            name='small'
                                            value={formData.small}
                                            className="form-control"
                                            placeholder='Bet Amount For Small'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For Tie</label>
                                        <input
                                            type="number"
                                            name='tie'
                                            value={formData.tie}
                                            className="form-control"
                                            placeholder='Bet Amount For Tie'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Amount For Big</label>
                                        <input
                                            type="number"
                                            name='big'
                                            value={formData.big}
                                            className="form-control"
                                            placeholder='Bet Amount For Big'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-2">
                                    <button type="submit" className="btn btn-primary mb-2 float-right">
                                        Update
                                    </button>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type="submit" className="btn btn-primary mb-2 float-right">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllBetSetting;
