import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {checkLoginStatus} from "../redux/actions/login/loginActions";

/// React router dom
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Analytics from "./components/Dashboard/Analytics";
import Events from "./components/Dashboard/Events";
import OrderList from "./components/Dashboard/OrderList";
import CustomerList from "./components/Dashboard/CustomerList";
import Reviews from "./components/Dashboard/Reviews";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import RechartJs from "./components/charts/rechart";

/// Widget
import Widget from "./pages/Widget";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
import ApexChart from "./components/charts/apexcharts";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import EventSidebar from "./layouts/EventSidebar";



//dream patti data
import ChangePassword from "./components/dreampatti/changePassword/ChangePassword";
import AdminList from "./components/dreampatti/adminList/AdminList";

//user management
import UserList from "./components/dreampatti/userManagement/Users";
import AgentList from "./components/dreampatti/userManagement/Agents";
import PendingRechargeList from "./components/dreampatti/recharge/pending";
import SuccessRechargeList from "./components/dreampatti/recharge/success";
import FailedRechargeList from "./components/dreampatti/recharge/failed";
import PendingWithdrawList from "./components/dreampatti/withdraw/pending";
import SuccessWithdrawList from "./components/dreampatti/withdraw/success";
import FailedWithdrawList from "./components/dreampatti/withdraw/failed";
import DailyBonus from "./components/dreampatti/bonus/DailyBonus";
import RewardTask from "./components/dreampatti/bonus/RewardBonus";
import RechargeBonus from "./components/dreampatti/bonus/RechargeBonus";
import AllSetting from "./components/dreampatti/gameSetting/AllGameCommonSetting";
import AllBetSetting from "./components/dreampatti/gameSetting/betAmountSetting";
import GiftCode from "./components/dreampatti/giftCode/giftCode";
import NextResultDiamonds from "./components/dreampatti/nextResult/nextResultDiamonds";
import NextResultHearts from "./components/dreampatti/nextResult/nextResultHearts";
import NextResultClubs from "./components/dreampatti/nextResult/nextResultClubs";
import NextResultSpades from "./components/dreampatti/nextResult/nextResultSpades";
import SingleUserInfo from "./components/dreampatti/userManagement/info/singleUserInfo";


const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);

const UnsecuredRoute = ({ component: Component, isLoggedIn, ...rest }) => {
   return (
       <Route
           {...rest}
           render={(props) =>
               isLoggedIn ? <Redirect to="/" /> : <Component {...props} />
           }
       />
   );
};

const Markup = ({ isLoggedIn, checkLoginStatus }) => {
   let path = window.location.pathname;
   path = path.split("/");
   path = path[path.length - 1];
   let pagePath = path.split("-").includes("page");

   const [activeEvent, setActiveEvent] = useState(false); //useState(!path);

   const routes = [
      /// Dashboard
      { url: "", component: Home },
      { url: "analytics", component: Analytics },
      { url: "events", component: Events },
      { url: "order-list", component: OrderList },
      { url: "customer-list", component: CustomerList },
      { url: "reviews", component: Reviews },

      /// Apps
      { url: "app-profile", component: AppProfile },
      { url: "email-compose", component: Compose },
      { url: "email-inbox", component: Inbox },
      { url: "email-read", component: Read },
      { url: "app-calender", component: Calendar },
      { url: "post-details", component: PostDetails },

      /// Chart
      { url: "chart-sparkline", component: SparklineChart },
      { url: "chart-chartjs", component: ChartJs },
      { url: "chart-chartist", component: Chartist },
      { url: "chart-apexchart", component: ApexChart },
      { url: "chart-rechart", component: RechartJs },

      /// Bootstrap
      { url: "ui-alert", component: UiAlert },
      { url: "ui-badge", component: UiBadge },
      { url: "ui-button", component: UiButton },
      { url: "ui-modal", component: UiModal },
      { url: "ui-button-group", component: UiButtonGroup },
      { url: "ui-accordion", component: UiAccordion },
      { url: "ui-list-group", component: UiListGroup },
      { url: "ui-media-object", component: UiMediaObject },
      { url: "ui-card", component: UiCards },
      { url: "ui-carousel", component: UiCarousel },
      { url: "ui-dropdown", component: UiDropDown },
      { url: "ui-popover", component: UiPopOver },
      { url: "ui-progressbar", component: UiProgressBar },
      { url: "ui-tab", component: UiTab },
      { url: "ui-pagination", component: UiPagination },
      { url: "ui-typography", component: UiTypography },
      { url: "ui-grid", component: UiGrid },

      /// Plugin
      { url: "uc-select2", component: Select2 },
      { url: "uc-noui-slider", component: MainNouiSlider },
      { url: "uc-sweetalert", component: MainSweetAlert },
      { url: "uc-toastr", component: Toastr },
      { url: "map-jqvmap", component: JqvMap },

      /// Widget
      { url: "widget-basic", component: Widget },


      /// Form
      { url: "form-element", component: Element },
      { url: "form-wizard", component: Wizard },
      { url: "form-wizard", component: Wizard },
      { url: "form-pickers", component: Pickers },
      { url: "form-validation-jquery", component: jQueryValidation },

      /// table
      { url: "table-datatable-basic", component: DataTable },
      { url: "table-bootstrap-basic", component: BootstrapTable },

      /// pages
      { url: "page-register", component: Registration },
      { url: "page-lock-screen", component: LockScreen },
      { url: "page-login", component: Login },
      { url: "page-forgot-password", component: ForgotPassword },
      { url: "page-error-400", component: Error400 },
      { url: "page-error-403", component: Error403 },
      { url: "page-error-404", component: Error404 },
      { url: "page-error-500", component: Error500 },
      { url: "page-error-503", component: Error503 },


      { url: "change-password", component: ChangePassword},
      { url: "admin-list", component: AdminList},

       //usermangement
      { url: "users", component: UserList},
      { url: "agents", component: AgentList},
      { url: "recharge/pending", component: PendingRechargeList},
      { url: "recharge/success", component: SuccessRechargeList},
      { url: "recharge/failed", component: FailedRechargeList},
      { url: "withdraw/pending", component: PendingWithdrawList},
      { url: "withdraw/success", component: SuccessWithdrawList},
      { url: "withdraw/failed", component: FailedWithdrawList},
      { url: "dailyBonus", component: DailyBonus},
      { url: "tasks", component: RewardTask},
      { url: "rechargeLimit", component: RechargeBonus},
      { url: "allSetting", component: AllSetting},
      { url: "allBetSetting", component: AllBetSetting},
      { url: "giftCode", component: GiftCode},
      { url: "nextResultSpades", component: NextResultSpades},
      { url: "nextResultClubs", component: NextResultClubs},
      { url: "nextResultHearts", component: NextResultHearts},
      { url: "nextResultDiamond", component: NextResultDiamonds},
      { url: "userInfo/:userId", component: SingleUserInfo }

   ];


   useEffect(() => {
      // Check login status when the app loads
      checkLoginStatus();
   }, [checkLoginStatus]);

   return (
       <div>
          <Router >
             {isLoggedIn ? (
                 <div id={`${!pagePath ? "main-wrapper" : ""}`} className={`${!pagePath ? "show" : "mh100vh"}`}>
                    {!pagePath && (
                        <Nav
                            onClick={() => setActiveEvent(!activeEvent)}
                            activeEvent={activeEvent}
                            onClick2={() => setActiveEvent(false)}
                            onClick3={() => setActiveEvent(true)}
                        />
                    )}
                    {!pagePath && <EventSidebar activeEvent={activeEvent}/>}
                    <div
                        className={` ${!path && activeEvent ? "rightside-event" : ""} ${!pagePath ? "content-body" : ""}`}>
                       <div className={`${!pagePath ? "container-fluid" : ""}`}>
                          <Switch>
                             {routes.map((data, i) => (
                                 <PrivateRoute
                                     key={i}
                                     exact
                                     path={`/${data.url}`}
                                     component={data.component}
                                     isLoggedIn={isLoggedIn}
                                 />
                             ))}
                             <Redirect to="/" />
                          </Switch>
                       </div>
                    </div>
                    {!pagePath && <Footer/>}
                 </div>
             ) : (
                 <div className={'mh100vh'}>
                    <Switch>
                    <UnsecuredRoute path="/" component={Login} exact/>
                    <UnsecuredRoute path="/login" component={Login} exact/>
                    <UnsecuredRoute path="/signup" component={Registration} exact/>
                    <UnsecuredRoute path="/forgot-password" component={ForgotPassword} exact/>
                    {/*<Redirect to="/" />*/}
                    </Switch>
                 </div>
             )
             }

          </Router>
       </div>
   );
};

const mapStateToProps = (state) => ({
   isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = {
   checkLoginStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Markup);
