const superAdmin = [
    {
        name: 'Dashboard',
        url: '/',
        icon: 'flaticon-381-networking',
        nested: false
    },
    {
        name: 'Admin Users',
        url: '/admin-list',
        icon: 'fa fa-user',
        nested: false
    },
    {
        name: 'User Management',
        icon: 'fa fa-users',
        nested: true,
        data: [
            {
                name: 'Users',
                url: '/users',
            },
            {
                name: 'Agents',
                url: '/agents',
            },
        ]
    },
    {
        name: 'Recharge',
        icon: 'fa fa-battery-full',
        nested: true,
        data: [
            {
                name: 'Pending',
                url: '/recharge/pending',
            },
            {
                name: 'Approved',
                url: '/recharge/success',
            },
            {
                name: 'Rejected',
                url: '/recharge/failed',
            },
        ]
    },
    {
        name: 'Withdraw',
        icon: 'fa fa-money',
        nested: true,
        data: [
            {
                name: 'Pending',
                url: '/withdraw/pending',
            },
            {
                name: 'Approved',
                url: '/withdraw/success',
            },
            {
                name: 'Rejected',
                url: '/withdraw/failed',
            },
        ]
    },
    {
        name: 'Bonus',
        icon: 'fa fa-gift',
        nested: true,
        data: [
            {
                name: 'Daily Bonus',
                url: '/dailyBonus',
            },
            {
                name: 'Recharge Bonus',
                url: '/rechargeLimit',
            },
            {
                name: 'Reward Task',
                url: '/tasks',
            },
            {
                name: 'Gift Code',
                url: '/giftCode',
            },
        ]
    },
    {
        name: 'Game Settings',
        icon: 'fa fa-cogs',
        nested: true,
        data: [
            {
                name: 'All Setting',
                url: '/allSetting',
            },
            {
                name: 'All Bet Setting',
                url: '/allBetSetting',
            },
        ]
    },
    {
        name: 'Next Result',
        icon: 'fa fa-tasks',
        nested: true,
        data: [
            {
                name: 'Spades Game',
                url: '/nextResultSpades',
            },
            {
                name: 'Hearts Game',
                url: '/nextResultHearts',
            },
            {
                name: 'Clubs Game',
                url: '/nextResultClubs',
            },
            {
                name: 'Diamonds Game',
                url: '/nextResultDiamond',
            },
        ]
    },
    {
        name: 'Setting',
        icon: 'fa fa-cog',
        nested: true,
        data: [
            {
                name: 'Password Change',
                url: '/change-password',
            }
        ]
    },
];

export default superAdmin;
