import React from "react";
import {Link} from "react-router-dom";
import {FiBarChart2, FiDollarSign, FiUsers, FiActivity, FiCreditCard, FiAward} from "react-icons/fi";
import {Bar} from "react-chartjs-2";

const DashboardBox = ({data}) => {
    // Prepare chart data
    const getMonthName = (monthIndex) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[monthIndex];
    };
    const chartLabels = data?.mergedData?.map(item => getMonthName(item.month - 1)); // Adjust index for zero-based months

    // Prepare chart data
    const rechargeData = data?.mergedData?.map(item => item.monthlyRechargeTotal);
    const withdrawData = data?.mergedData?.map(item => item.monthlyWithdrawTotal);

    // Chart.js data object
    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Monthly Recharge',
                backgroundColor: 'rgba(108, 92, 231, 0.6)',
                borderColor: 'rgba(108, 92, 231, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(108, 92, 231, 0.8)',
                hoverBorderColor: 'rgba(108, 92, 231, 1)',
                data: rechargeData
            },
            {
                label: 'Monthly Withdraw',
                backgroundColor: 'rgba(253, 126, 20, 0.6)',
                borderColor: 'rgba(253, 126, 20, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(253, 126, 20, 0.8)',
                hoverBorderColor: 'rgba(253, 126, 20, 1)',
                data: withdrawData
            }
        ]
    };

    // Chart.js options object
    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };

    return (
        <div>
            <div className="card widget-media" style={{minHeight: "300px", minWidth: "300px"}}>
                <div className="card-header border-0 pb-0">
                    <h4 className="text-black">Dashboard Details</h4>
                    <Link to="/" className="btn-link icon-false p-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                        >
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <circle fill="#000000" cx="12" cy="5" r="2"></circle>
                                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                <circle fill="#000000" cx="12" cy="19" r="2"></circle>
                            </g>
                        </svg>
                    </Link>
                </div>
                <div className="row card-body timeline pb-2">
                    {/* New Recharge Requests */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiBarChart2 size={30} color="#6c5ce7"/>
                                <h5 className="mb-1">Recharge Requests</h5>
                                <p className="d-block mb-0">{data.newRechargeRequests}</p>
                            </div>
                        </div>
                    </div>

                    {/* New Withdraw Requests */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiDollarSign size={30} color="#fd7e14"/>
                                <h5 className="mb-1">Withdraw Requests</h5>
                                <p className="d-block mb-0">{data.newWithdrawRequests}</p>
                            </div>
                        </div>
                    </div>

                    {/* Today's New Players */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiUsers size={30} color="#20c997"/>
                                <h5 className="mb-1">Today's New Players</h5>
                                <p className="d-block mb-0">{data.todayNewPlayers}</p>
                            </div>
                        </div>
                    </div>

                    {/* Today's Profit */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiActivity size={30} color="#007bff"/>
                                <h5 className="mb-1">Today's Profit</h5>
                                <p className="d-block mb-0">{data.todayProfit}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Agents */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiUsers size={30} color="#6610f2"/>
                                <h5 className="mb-1">Total Agents</h5>
                                <p className="d-block mb-0">{data.totalAgents}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Balance */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiDollarSign size={30} color="#28a745"/>
                                <h5 className="mb-1">Total Balance</h5>
                                <p className="d-block mb-0">{data.totalBalance}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Bonus */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiAward size={30} color="#17a2b8"/>
                                <h5 className="mb-1">Total Bonus</h5>
                                <p className="d-block mb-0">{data.totalBonus}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Players */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiUsers size={30} color="#6f42c1"/>
                                <h5 className="mb-1">Total Players</h5>
                                <p className="d-block mb-0">{data.totalPlayers}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Recharge Amount */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiCreditCard size={30} color="#dc3545"/>
                                <h5 className="mb-1">Total Recharge</h5>
                                <p className="d-block mb-0">{data.totalRechargeAmount}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Today Recharge */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiCreditCard size={30} color="#17a2b8"/>
                                <h5 className="mb-1">Today Recharge</h5>
                                <p className="d-block mb-0">{data.totalTodayRecharge}</p>
                            </div>
                        </div>
                    </div>

                    {/* Total Withdraw Amount */}
                    <div className="timeline-panel align-items-end" style={{minHeight: "200px", minWidth: "200px"}}>
                        <div className="media mr-3" style={{height: "100%", width: "100%"}}>
                            <div className="media-body d-flex flex-column justify-content-center align-items-center">
                                <FiCreditCard size={30} color="#17a2b8"/>
                                <h5 className="mb-1">Total Withdraw</h5>
                                <p className="d-block mb-0">{data.totalWithdrawAmount}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Chart */}
            <div className="mt-4">
                <Bar data={chartData} options={options} />
            </div>
        </div>
    );
};

export default DashboardBox;
