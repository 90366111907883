// reducers.js
import { combineReducers } from 'redux';
import loginReducer from './reducers/login/loginReducer'; // Create this file for your plans data

const rootReducer = combineReducers({
    auth: loginReducer,
    // Add more reducers if needed
});

export default rootReducer;
