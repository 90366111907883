import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { handleFormInput } from '../../../../common/form';
import TableView from './TableView';
import { add_new_next_result, get_all_next_result } from '../../../../api/secure';

const NextResultDiamonds = () => {
    const [formData, setFormData] = useState({
        game: 'Diamonds',
        result: '',
    });
    const [pendingBets, setPendingBets] = useState([]);
    const [newBets, setNewBets] = useState([]);
    const [users, setUsers] = useState([]);
    const [seconds, setSeconds] = useState('60'); // State to hold real-time seconds
    const [socket, setSocket] = useState(null);
    const gameType = 'Diamonds'; // Replace with dynamic game type as needed

    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({ e, formData: formData, payload });
        //console.log("ct", ct);
        setFormData(ct);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await add_new_next_result({ formData });
        console.log('formData', formData);
        await getAllUser();
    };

    const getAllUser = async () => {
        const dt = await get_all_next_result();
        console.log('dt', dt);
        setUsers(dt);
    };

    useEffect(() => {
        getAllUser();
    }, []);

    useEffect(() => {
        let url;
        if (process.env.REACT_APP_ENV === "development") {
            url = process.env.REACT_APP_DEV_SOCKET_API;
        } else {
            url = process.env.REACT_APP_PRO_SOCKET_API;
        }
        if(!url) {
            throw new Error("url is not defined in environment variables.");
        }
        const newSocket = io(url);

        newSocket.on('connect', () => {
            console.log('Connected to server');
            // alert('Connected to server');

            // Request to fetch all pending bets for a specific game type
            newSocket.emit('fetchPendingBets', { gameType });
        });

        // Listen for updates on all pending bets
        newSocket.on('pendingBets', (bets) => {
            console.info('pending', bets);
            if (bets.game === gameType) {
                setPendingBets(bets);
            }
        });

        // Listen for new bet notifications
        newSocket.on('newBet', (data) => {
            console.info('newBet', data);
            if (data.savedData.game === gameType) {
                setNewBets((prevBets) => [data.savedData, ...prevBets]);
            }
        });

        newSocket.on('disconnect', () => {
            console.log('Disconnected from server');
            // alert('Disconnected from server');
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, []);

    const getBetMessage = (bet) => {
        const betType = bet.select;
        let displayText;
        if (betType === '1') {
            displayText = 'Ace';
        } else if (betType === '11') {
            displayText = 'J';
        } else if (betType === '12') {
            displayText = 'Q';
        } else if (betType === '13') {
            displayText = 'K';
        } else if (betType === 'small' || betType === 'tie' || betType === 'big') {
            displayText = betType.charAt(0).toUpperCase() + betType.slice(1);
        } else {
            displayText = betType;
        }

        return (
            <>
                Amount <span className="text-white font-bold">{bet.amount}</span> bet on{' '}
                <span className="text-white font-bold">{displayText}</span> by{' '}
                <span className="text-white font-bold">{bet.playerId}</span>
            </>
        );
    };

    const getBetBgColor = (betType) => {
        if (betType === 'Big') {
            return 'bg-success';
        } else if (betType === 'Small') {
            return 'bg-danger';
        } else if (betType === 'Tie') {
            return 'bg-primary';
        } else if (betType === '1' || betType === '11' || betType === '12' || betType === '13') {
            return 'bg-warning';
        } else {
            return 'bg-info';
        }
    };

    const intervalRef = useRef(null);

    // Function to synchronize fetching with the start of the next minute in UTC time
    useEffect(() => {
        const synchronizeFetch = () => {
            const now = new Date();
            const msUntilNextMinute = (60 - now.getUTCSeconds()) * 1000 - now.getUTCMilliseconds();

            setTimeout(() => {
                setNewBets([]);
                setPendingBets([]);
                // Request to fetch all pending bets for a specific game type
                socket.emit('fetchPendingBets', { gameType });
                intervalRef.current = setInterval(() => {
                    setNewBets([]);
                    setPendingBets([]);
                    // Request to fetch all pending bets for a specific game type
                    if (socket) {
                        socket.emit('fetchPendingBets', { gameType });
                    }
                }, 60000);
            }, msUntilNextMinute);
        };

        synchronizeFetch();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [socket]);

    // Set unique ID and start timer for seconds
    useEffect(() => {
        // Start the timer interval to update seconds every second
        const intervalId = setInterval(() => {
            setSeconds(getRealtimeSeconds());
        }, 1000);

        // Cleanup function for useEffect
        return () => {
            clearInterval(intervalId); // Clear the interval on component unmount
        };
    }, []);

    // Function to get real-time seconds in reversed order
    const getRealtimeSeconds = () => {
        const currentDate = new Date();
        return ('0' + (59 - currentDate.getSeconds())).slice(-2); // Get reversed real-time seconds
    };

    const colors = [
        'bg-primary text-white', 'bg-secondary text-black', 'bg-success text-white', 'bg-danger text-white', 'bg-warning text-white', 'bg-info text-white', 'bg-success text-white',
        'bg-primary text-white', 'bg-secondary text-black', 'bg-success text-white', 'bg-danger text-white', 'bg-warning text-white', 'bg-info text-white',
    ];

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <div>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title ">Next Result Diamonds</h4>
                            </div>
                            <div className="card-body">
                                <form action={''} onSubmit={(e) => submitHandler(e)}>
                                    <input type="hidden" name="collectionName" value="ChargerType" />
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="form-group text-left">
                                                <label className="text-label">Next Result Between (1-13)*</label>
                                                <input
                                                    onChange={handleBlur}
                                                    type="number"
                                                    name="result"
                                                    className="form-control"
                                                    placeholder="Next Result Between (1-13)"
                                                    min={1}
                                                    max={13}
                                                    maxLength={2}
                                                    minLength={1}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <button type="submit" className="btn btn-primary float-right">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Min Amt Win Cases</h4>
                            </div>
                            <div className="card-body" style={{ maxHeight: '300px' }}>
                                <div className="row">
                                    {pendingBets.minAmountCases && pendingBets.minAmountCases.length > 0
                                        ? pendingBets.minAmountCases.map((amount, index) => (
                                            <div className="col-2" key={index}>
                                                <li>{amount}</li>
                                            </div>
                                        ))
                                        : Array.from({ length: 13 }, (_, i) => (
                                            <div className="col-2" key={i}>
                                                <li>{i + 1}</li>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">New Bets</h4>
                        </div>
                        <div className="card-body" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                            <div className="row">
                                {newBets.length > 0 ? (
                                    newBets.map((bet, index) => (
                                        <div className={`col-12 mb-2 rounded-lg p-2 ${getBetBgColor(bet.select)}`} key={index}>
                                            <li className="text-white text-center">{getBetMessage(bet)}</li>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-12">
                                        <li>No new bets</li>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-6">
                    {users.length > 0 && (
                        <TableView title={`Next Results Manual `} seconds={seconds} reloadDB={() => getAllUser()} data={users} actionShow={false} />
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Result Total Amounts</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {Array.from({ length: 13 }, (_, i) => {
                                    return (
                                        <div className="col-1 m-2 p-2" key={i}>
                                            <button className={`btn ${colors[i]}`}>
                                                <h4 className={` ${colors[i]}`}>{i + 1}</h4>
                                                {pendingBets.caseTotalAmounts ? pendingBets.caseTotalAmounts[i + 1] || 0 : 0}
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Bets Total Money</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {Array.from({ length: 13 }, (_, i) => {
                                    let displayText;
                                    if (i + 1 === 1) {
                                        displayText = 'A';
                                    } else if (i + 1 === 11) {
                                        displayText = 'J';
                                    } else if (i + 1 === 12) {
                                        displayText = 'Q';
                                    } else if (i + 1 === 13) {
                                        displayText = 'K';
                                    } else {
                                        displayText = (i + 1).toString();
                                    }

                                    return (
                                        <div className="col-1 m-2" key={i}>
                                            <button className={`btn ${colors[i]}`}>
                                                <h4 className={` ${colors[i]}`}>{displayText}</h4>
                                                {pendingBets.conditionTotalMoney ? pendingBets.conditionTotalMoney[i + 1] || 0 : 0}
                                            </button>
                                        </div>
                                    );
                                })}
                                <div className="col-1 m-2 mr-5">
                                    <button className="btn btn-danger min-vw-[200px]">
                                        <h6 className="text-white">Small</h6>
                                        {pendingBets.conditionTotalMoney ? pendingBets.conditionTotalMoney.Small || 0 : 0}
                                    </button>
                                </div>
                                <div className="col-1 m-2 mr-3">
                                    <button className="btn btn-primary min-vw-[200px]">
                                        <h5 className="text-white">Tie</h5>
                                        {pendingBets.conditionTotalMoney ? pendingBets.conditionTotalMoney.Tie || 0 : 0}
                                    </button>
                                </div>
                                <div className="col-1 m-2">
                                    <button className="btn btn-success min-vw-[200px]">
                                        <h5 className="text-white">Big</h5>
                                        {pendingBets.conditionTotalMoney ? pendingBets.conditionTotalMoney.Big || 0 : 0}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NextResultDiamonds;
