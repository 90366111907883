import React, {useState, useEffect} from "react";

import PageTitle from "../../../layouts/PageTitle";
import {handleFormInput} from "../../../../common/form"
import TableView from "./TableView";
import {add_new_giftCode, get_all_giftCode, update_gift_status} from "../../../../api/secure";

const GiftCode = ({history}) => {
    const [formData, setFormData] = useState({});
    const [users, setUsers] = useState([]);
    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({e, formData: formData, payload});
        //console.log("ct", ct);
        setFormData(ct);
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        await add_new_giftCode({formData});
        await getAllUser();
    };

    const updateStatus = async (updateData) => {
        await update_gift_status(updateData);
        await getAllUser();
    }

    const getAllUser = async () => {
        const dt = await get_all_giftCode();
        //console.log("dt", dt);
        setUsers(dt);
    };

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="User"/>
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Create Coupon</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType"/>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Title*</label>
                                        <input
                                            type="text"
                                            name="title"
                                            className="form-control"
                                            placeholder="Title"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Gift Type*</label>
                                        <select
                                            onChange={handleBlur}
                                            name="type"
                                            defaultValue={''}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="Fixed">Fixed</option>
                                            <option value="Random">Random</option>
                                        </select>
                                    </div>
                                </div>
                                {formData.type === 'Fixed' &&
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group text-left">
                                            <label className="text-label">Fixed Amount*</label>
                                            <input
                                                type="number"
                                                name="fixedAmount"
                                                className="form-control"
                                                placeholder="Fixed Amount"
                                                onChange={handleBlur}
                                                required
                                            />
                                        </div>
                                    </div>
                                }
                                {formData.type === 'Random' &&
                                    <div className="col-lg-4 mb-2">
                                        <div className="form-group text-left">
                                            <label className="text-label">Random Amount*</label>
                                            <input
                                                type="number"
                                                name="randomAmount"
                                                className="form-control"
                                                placeholder="Random Amount"
                                                onChange={handleBlur}
                                                required
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Max Use*</label>
                                        <input
                                            onChange={handleBlur}
                                            type="number"
                                            name="maxUse"
                                            className="form-control"
                                            placeholder="Max Use"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Expire Date*</label>
                                        <input
                                            type="datetime-local"
                                            name="expireDateTime"
                                            className="form-control"
                                            placeholder="Expire Date"
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type='submit' className='btn btn-primary mb-2 float-right'>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="row">
                {users.length > 0 &&
                    <TableView
                        title={"Users"}
                        reloadDB={() => getAllUser()}
                        data={users}
                        updateStatus={(updateData) => updateStatus(updateData)}
                        actionShow={false}
                    />
                }
            </div>
        </>
    );
};

export default GiftCode;
