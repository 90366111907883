import React, { useState} from "react";
import {Badge, Button, Form, Modal, Table} from "react-bootstrap";
import Moment from "moment";


const columns = [
    'Type',
    'MinAmount',
    'MaxAmount',
    'Bonus',
    'Create Date',
    'Status',
    'Action',
    'Update',
];



const TableView = ({title, data, actionShow, reloadDB, updateStatus, updateDetails}) => {
    const [show, setShow] = useState(false);
    const [updateData, setUpdateData] = useState({});
    const [formData, setFormData] = useState({ min: '', max: '', bonus: '' });

    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setFormData({ min: data.min, max: data.max, bonus: data.bonus });
        setUpdateData(data);
        setShow(true);
    };

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleUpdate = () => {
        const parsedUpdateData = {
            ...updateData,
            ...formData,
            max: parseInt(formData.max) // Parse 'max' to number
        };
        updateDetails({ updateData: parsedUpdateData });
        handleClose();
    };



    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{title}</h4>
                </div>
                <div className="card-body">
                    <Table responsive className="w-100">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                                <thead>
                                <tr role="row">
                                    <th>SR.</th>
                                    {columns.map((d, i) => (
                                        <th key={i}>{d}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {data.map((d, i) => {
                                        if (d.type) {
                                            return (
                                                <tr key={i}>
                                                    {/*<td key={i}>
                                                        {
                                                            d.profile_image &&
                                                            <img
                                                                src={Constants.image_url + '' + d.profile_image}
                                                                className="rounded-circle"
                                                                alt="profile"
                                                                width={50}
                                                                height={50}
                                                            />
                                                        }
                                                    </td>*/}
                                                    <td>
                                                        {i + 1}
                                                    </td>
                                                    <td>
                                                        {d.type}
                                                    </td>
                                                    <td>
                                                        {d.min}
                                                    </td>
                                                    <td>
                                                        {d.max}
                                                    </td>
                                                    <td>
                                                        {d.bonus}
                                                    </td>

                                                    <td>
                                                        {Moment(d.createdAt).locale('en').format('DD/MM/YYYY')}
                                                    </td>
                                                    <td>
                                                        <span style={{color: d.status === true ? 'green' : 'red'}}>
                                                            {d.status === true ? 'Active' : 'UnActive'}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {d.status === true ? (
                                                            <Badge variant="danger light cursor-pointer"
                                                                   onClick={() => updateStatus({
                                                                       updateData: {
                                                                           id: d._id,
                                                                           status: false
                                                                       }
                                                                   })}>
                                                                Deactivate
                                                            </Badge>
                                                        ) : (
                                                            <Badge variant="success light cursor-pointer"
                                                                   onClick={() => updateStatus({
                                                                       updateData: {
                                                                           id: d._id,
                                                                           status: true
                                                                       }
                                                                   })}>
                                                                Activate
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Badge variant="secondary light cursor-pointer"
                                                               onClick={() => handleShow(d)}>
                                                            Update
                                                        </Badge>
                                                    </td>

                                                </tr>
                                            )
                                        }
                                    }
                                )}
                                </tbody>
                            </table>

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Bonus Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Min Amount</Form.Label>
                                            <Form.Control type="number" name="min" value={formData.min} onChange={handleFormChange} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Max Amount</Form.Label>
                                            <Form.Control type="number" name="max" value={formData.max} onChange={handleFormChange} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Bonus</Form.Label>
                                            <Form.Control type="number" name="bonus" value={formData.bonus} onChange={handleFormChange} />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleUpdate}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/*    <div className="d-flex justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    Showing {activePag.current * sort + 1} to
                                    {data.length <
                                    (activePag.current + 1) * sort
                                        ? data.length
                                        : (activePag.current + 1) * sort}
                                    of {data.length} entries
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers">
                                    <Pagination
                                        className="pagination-primary pagination-circle"
                                        size="lg"
                                    >
                                        <a
                                            className="paginate_button previous disabled"
                                            to="Javascript:void"
                                            onClick={() =>
                                                activePag.current > 0 &&
                                                onClick(activePag.current - 1)
                                            }
                                        >
                                            Previous
                                        </a>
                                        {jobPagination.map((number, i) => (
                                            <Pagination.Item
                                                key={i}
                                                className={
                                                    activePag.current === i ? "active" : ""
                                                }
                                                onClick={() => onClick(i)}
                                            >
                                                {number}
                                            </Pagination.Item>
                                        ))}
                                        <a
                                            className="paginate_button next"
                                            to="Javascript:void"
                                            onClick={() =>
                                                activePag.current + 1 <
                                                jobPagination.length &&
                                                onClick(activePag.current + 1)
                                            }
                                        >
                                            Next
                                        </a>
                                    </Pagination>
                                </div>
                            </div>*/}
                        </div>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default TableView;
