import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { handleFormInput } from "../../../../common/form";
import {add_all_setting, get_all_setting} from "../../../../api/secure";

const AllSetting = () => {
    const [formData, setFormData] = useState({
        welcomeBonus: "",
        upiId: "",
        upiUserName: "",
        upiMinRec: "",
        upiMaxRec: "",
        ifsc: "",
        bankAccountNumber: "",
        bankName: "",
        bankMinRec: "",
        bankMaxRec: "",
        usdt: "",
        usdtMinRec: "",
        usdtMaxRec: "",
        otpMail: "",
        otpMailType: "",
        otpEMailServiceHost: "",
        otpEmailPass: "",
        otpMailSecure: "",
        otpEmailPort: "",
        dollarRate: "",
        withUpiMin: "",
        withUpiMax: "",
        withBankMin: "",
        withBankMax: "",
        withUsdtMin: "",
        withUsdtMax: "",
        withDrawFee: "",
        dailyWithdrawLimit: "",
        dailyWithdrawTime: "",
        helpEmail: "",
        telegramLink: "",
        level1: "",
        level2: "",
        level3: "",
        betFee: "",
    });

    const handleBlur = (e, payload = {}) => {
        let ct = handleFormInput({ e, formData: formData, payload });
        console.log("ct", ct);
        setFormData(ct);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await add_all_setting({ formData });
        await getAllUser();
    };

    const getAllUser = async () => {
        const dt = await get_all_setting();
        console.log("dt", dt);

        if (dt.length > 0) {
            setFormData(dt[0]);
        }
    };

    useEffect(() => {
        getAllUser();
    }, []);

    return (
        <>
            <PageTitle activeMenu="Admin" motherMenu="User" />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Update Settings</h4>
                    </div>
                    <div className="card-body">
                        <form
                            action={""}
                            onSubmit={(e) => submitHandler(e)}
                        >
                            <input type="hidden" name="collectionName" value="ChargerType" />
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Welcome Bonus</label>
                                        <input
                                            type="number"
                                            name='welcomeBonus'
                                            value={formData.welcomeBonus}
                                            className="form-control"
                                            placeholder='Welcome Bonus'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game UPI ID</label>
                                        <input
                                            type="text"
                                            name='upiId'
                                            value={formData.upiId}
                                            className="form-control"
                                            placeholder='Game Upi Id'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game UPI User Name</label>
                                        <input
                                            type="text"
                                            name='upiUserName'
                                            value={formData.upiUserName}
                                            className="form-control"
                                            placeholder='UPI BANK NAME'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game UPI Min Rec Amt</label>
                                        <input
                                            type="number"
                                            name='upiMinRec'
                                            value={formData.upiMinRec}
                                            className="form-control"
                                            placeholder='Game Upi Min Rec Amt'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game UPI Max Rec Amt</label>
                                        <input
                                            type="number"
                                            name='upiMaxRec'
                                            value={formData.upiMaxRec}
                                            className="form-control"
                                            placeholder='Game Upi Max Rec Amt'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bank IFSC</label>
                                        <input
                                            type="text"
                                            name='ifsc'
                                            value={formData.ifsc}
                                            className="form-control"
                                            placeholder='IFSC Code'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bank Account Number</label>
                                        <input
                                            type="number"
                                            name='bankAccountNumber'
                                            value={formData.bankAccountNumber}
                                            className="form-control"
                                            placeholder='Game bankAccountNumber'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game Bank Name</label>
                                        <input
                                            type="text"
                                            name='bankName'
                                            value={formData.bankName}
                                            className="form-control"
                                            placeholder=' BANK NAME'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game Bank Min Rec Amt</label>
                                        <input
                                            type="number"
                                            name='bankMinRec'
                                            value={formData.bankMinRec}
                                            className="form-control"
                                            placeholder='Game Bank Min Rec Amt'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game Bank Max Rec Amt</label>
                                        <input
                                            type="number"
                                            name='bankMaxRec'
                                            value={formData.bankMaxRec}
                                            className="form-control"
                                            placeholder='Game Bank Max Rec Amt'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game USDT WALLET NUMBER</label>
                                        <input
                                            type="text"
                                            name='usdt'
                                            value={formData.usdt}
                                            className="form-control"
                                            placeholder=' USDT WALLET '
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game USDT Min Rec Amt</label>
                                        <input
                                            type="number"
                                            name='usdtMinRec'
                                            value={formData.usdtMinRec}
                                            className="form-control"
                                            placeholder='Game Usdt Min Rec Amt'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Game Usdt Max Rec Amt</label>
                                        <input
                                            type="number"
                                            name='usdtMaxRec'
                                            value={formData.usdtMaxRec}
                                            className="form-control"
                                            placeholder='Game Usdt Max Rec Amt'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">OTP Email Type</label>
                                        <select
                                            onChange={handleBlur}
                                            name="otpMailType"
                                            value={formData.otpMailType}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="Service">Service</option>
                                            <option value="Host">Host</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">OTP Email Provider</label>
                                        <select
                                            onChange={handleBlur}
                                            name="otpEMailServiceHost"
                                            value={formData.otpEMailServiceHost}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="gmail">Gmail</option>
                                            <option value="yahoo">yahoo</option>
                                            <option value="hotmail">hotmail</option>
                                            <option value="icloud">icloud</option>
                                            <option value="aol">aol</option>
                                            <option value="zoho">zoho</option>
                                            <option value="yandex">yandex</option>
                                            <option value="mail.ru">mail.ru</option>
                                            <option value="protonmail">protonmail</option>
                                            <option value="gmx">gmx</option>
                                            <option value="Mailgun">Mailgun</option>
                                            <option value="SendGrid">SendGrid</option>
                                            <option value="Postmark">Postmark</option>
                                            <option value="smtp.gmail.com">G Suite (Google Workspace) Host</option>
                                            <option value="smtp.office365.com">Microsoft Office 365 Host</option>
                                            <option value="smtp.zoho.com">Zoho Mail Host</option>
                                            <option value="127.0.0.1">ProtonMail (Bridge required) Host</option>
                                            <option value="smtp.mailgun.org">Mailgun Host</option>
                                            <option value="smtp.sendgrid.net">SendGrid Host</option>
                                            <option value="smtp.postmarkapp.com">Postmark Host</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">OTP Email Port</label>
                                        <select
                                            onChange={handleBlur}
                                            name="otpEmailPort"
                                            value={formData.otpEmailPort}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="465">465</option>
                                            <option value="587">587</option>
                                            <option value="1025">1025</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">OTP Email Secure</label>
                                        <select
                                            onChange={handleBlur}
                                            name="otpMailSecure"
                                            value={formData.otpMailSecure}
                                            className="form-control"
                                            required
                                        >
                                            <option value="">Select type</option>
                                            <option value="true">True //use SSl</option>
                                            <option value="false">False //use TLS</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">OTP Email</label>
                                        <input
                                            type="text"
                                            name='otpMail'
                                            value={formData.otpMail}
                                            className="form-control"
                                            placeholder='OTP EMAIL'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">OTP EMAIL PASS</label>
                                        <input
                                            type="text"
                                            name='otpEmailPass'
                                            value={formData.otpEmailPass}
                                            className="form-control"
                                            placeholder='OTP Email Pass'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Dollar Rate</label>
                                        <input
                                            type="number"
                                            name='dollarRate'
                                            value={formData.dollarRate}
                                            className="form-control"
                                            placeholder='Dollar Rate'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW UPI Min</label>
                                        <input
                                            type="number"
                                            name='withUpiMin'
                                            value={formData.withUpiMin}
                                            className="form-control"
                                            placeholder='WITHDRAW UPI Min'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW UPI Max</label>
                                        <input
                                            type="number"
                                            name='withUpiMax'
                                            value={formData.withUpiMax}
                                            className="form-control"
                                            placeholder='WITHDRAW UPI Max'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW Bank Min</label>
                                        <input
                                            type="number"
                                            name='withBankMin'
                                            value={formData.withBankMin}
                                            className="form-control"
                                            placeholder='WITHDRAW Bank Min'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW Bank Max</label>
                                        <input
                                            type="number"
                                            name='withBankMax'
                                            value={formData.withBankMax}
                                            className="form-control"
                                            placeholder='WITHDRAW Bank Max'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW USDT Min</label>
                                        <input
                                            type="number"
                                            name='withUsdtMin'
                                            value={formData.withUsdtMin}
                                            className="form-control"
                                            placeholder='WITHDRAW USDT Min'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW USDT Max</label>
                                        <input
                                            type="number"
                                            name='withUsdtMax'
                                            value={formData.withUsdtMax}
                                            className="form-control"
                                            placeholder='WITHDRAW USDT Max'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">WITHDRAW Fee %</label>
                                        <input
                                            type="number"
                                            name='withDrawFee'
                                            value={formData.withDrawFee}
                                            className="form-control"
                                            placeholder='WITHDRAW Fee'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Daily Withdraw Limit</label>
                                        <input
                                            type="number"
                                            name='dailyWithdrawLimit'
                                            value={formData.dailyWithdrawLimit}
                                            className="form-control"
                                            placeholder='Daily Withdraw Limit'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Daily Withdraw Time</label>
                                        <input
                                            type="text"
                                            name='dailyWithdrawTime'
                                            value={formData.dailyWithdrawTime}
                                            className="form-control"
                                            placeholder='Daily Withdraw Time (e.g., 10AM to 8PM)'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Help Email</label>
                                        <input
                                            type="email"
                                            name='helpEmail'
                                            value={formData.helpEmail}
                                            className="form-control"
                                            placeholder='Help Email'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Telegram Link</label>
                                        <input
                                            type="text"
                                            name='telegramLink'
                                            value={formData.telegramLink}
                                            className="form-control"
                                            placeholder='Telegram Link'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Level 1 Commission %</label>
                                        <input
                                            type="text"
                                            name='level1'
                                            value={formData.level1}
                                            className="form-control"
                                            placeholder='Level 1 Commission'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Level 2 Commission %</label>
                                        <input
                                            type="text"
                                            name='level2'
                                            value={formData.level2}
                                            className="form-control"
                                            placeholder='Level 2 Commission'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Level 3 Commission %</label>
                                        <input
                                            type="text"
                                            name='level3'
                                            value={formData.level3}
                                            className="form-control"
                                            placeholder='Level 3 Commission'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <div className="form-group text-left">
                                        <label className="text-label">Bet Fee %</label>
                                        <input
                                            type="text"
                                            name='betFee'
                                            value={formData.betFee}
                                            className="form-control"
                                            placeholder='Bet Fee'
                                            onChange={handleBlur}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                    <button type="submit" className="btn btn-primary mb-2 float-right">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllSetting;
