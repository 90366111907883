import React, { useEffect, useState, useCallback } from 'react';
import io from 'socket.io-client';

const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [isUserInteracted, setIsUserInteracted] = useState(false);

    const playNotificationSound = useCallback((type) => {
        const audioWithdraw = new Audio('./message-ringtone-magic.mp3');
        const audioRecharge = new Audio('./attention-man-deep-voice.mp3');
        audioRecharge.load();
        audioWithdraw.load();

        if (type === 'recharge') {
            audioRecharge.play().catch((error) => {
                console.error('Failed to play notification sound:', error);
            });
        } else if (type === 'withdraw') {
            audioWithdraw.play().catch((error) => {
                console.error('Failed to play notification sound:', error);
            });
        }
    }, []);

    const showNotification = useCallback((data) => {
        if (Notification.permission === 'granted') {
            playNotificationSound(data.type); // Play sound after showing the notification
            const notification = new Notification('New Request', {
                body: `${data.type.toUpperCase()}: ${JSON.stringify(data.data)}`,
                icon: 'http://localhost:3000/_next/image?url=%2Fimage%2Fhome%2Fgame1.png&w=256&q=75', // Ensure the icon path is correct
            });

            notification.onclick = () => {
                window.focus();
                notification.close();
            };
        } else {
            // Fallback to play sound if notifications are not allowed
            playNotificationSound(data.type);
        }
    }, [playNotificationSound]);

    useEffect(() => {
        // Request notification permission on component mount
        if (Notification.permission !== 'granted') {
            Notification.requestPermission()
                .then(permission => {
                    if (permission !== 'granted') {
                        console.error('Notification permission denied');
                    }
                });
        }

        const handleUserInteraction = () => {
            setIsUserInteracted(true);
            // Remove the event listener after the first interaction
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
        };

        // Attach event listeners to capture user interaction
        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);

        let url;
        if (process.env.REACT_APP_ENV === "development") {
            url = process.env.REACT_APP_DEV_SOCKET_API;
        } else {
            url = process.env.REACT_APP_PRO_SOCKET_API;
        }
        if (!url) {
            throw new Error("URL is not defined in environment variables.");
        }
        const newSocket = io(url);

        newSocket.on('connect', () => {
            console.log('Connected to server as Notification');
        });

        newSocket.on('newRequest', (data) => {
            setNotifications((prev) => [...prev, data]);
            console.log('new request data', data);
            playNotificationSound(data.type);
            if (isUserInteracted) {
                showNotification(data);
            } else {
                playNotificationSound(data.type);
            }
        });

        newSocket.on('disconnect', () => {
            console.log('Disconnected from server Notification');
        });

        return () => {
            newSocket.disconnect();
        };
    }, [isUserInteracted, showNotification, playNotificationSound]);

    return (
        <NotificationContext.Provider value={{ notifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export { NotificationProvider, NotificationContext };
