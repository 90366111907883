import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux'; // Import the Provider component
import store from './redux/store'; // Import your Redux store
import App from "./App";

import SimpleReactLightbox from "simple-react-lightbox";
import {NotificationProvider} from "./NotificationProvider";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}> {/* Wrap your App with the Provider and provide the store */}
            <SimpleReactLightbox>
                <NotificationProvider>
                    <App/>
                </NotificationProvider>
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
