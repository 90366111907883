import {login_api} from "../../../api/secure";
import swal from "sweetalert";

// Action Types
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Action Creators
export const loginSuccess = ( token, userType) => ({
    type: LOGIN,
    payload: { token, userType }, // Include the token and userType in the payload
});

export const logoutSuccess = () => ({
    type: LOGOUT,
});

// Thunk Actions
export const login = (loginData) => async (dispatch) => {
    try {
        const response = await login_api({ loginData });
        console.log('Login API Response:', response);
        if (response.message === 'Login successful') {
            const { token, userType } = response;

            // Store the token securely in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('userType', userType);

            dispatch(loginSuccess(token, userType));
        } else {
            // Handle login failure, e.g., display an error message
            await swal(
                "Warning!",
                response.message,
                "info"
            )
        }
    } catch (error) {
        console.error('Error in login action:', error);
        // Handle API request error, e.g., display an error message
        // Dispatch an action to update the Redux state with the error details
        // You can create a separate action type for this if needed
        // dispatch(loginError(error));
    }
};

export const logout = () => async (dispatch) => {
    try {
        dispatch(logoutSuccess());
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export const checkLoginStatus = () => (dispatch) => {
    const token = localStorage.getItem('token');
    const userType = localStorage.getItem('userType');

    if (token && userType) {
        // User is already logged in, dispatch the loginSuccess action
        dispatch(loginSuccess(token, userType));
    }
};
